export default {
  common: {
    app: 'Application',
    prod: 'Product',
    vps: 'VPS'
  },
  productType: {
    vm: 'VPS',
    app: 'Application',
    bare_metal: 'Bare Metal Server'
  },
  menu: {
    home: ['Home', 'Dashboard'],
    apps: ['Applications', 'View all applications', 'Application List'],
    affiliate: ['Affiliate', 'Affiliate'],
    vps: ['Virtual Servers', 'Virtual Private Server Hosting List', 'Virtual Server List'],
    ssl: ['SSL', 'SSL Digital Certificate Management Service List'],
    domain: ['Domain', 'Domain List'],
    dedicated: ['Dedicated Servers', 'Dedicated Server Hosting List', 'Dedicated Server List'],
    gpu: ['GPU Servers', 'GPU Server Hosting List', 'GPU Server List'],
    billing: ['Billing', 'Billing Information Management'],
    tickets: ['Support', 'Seek Help from Our Customer Service Team'],
    dns: ['DNS Management', 'Manage Your DNS Records'],
    all: ['All Products', 'Discover Popular Products'],

    products: {
      app: ['Applications', ''],
      vps: ['Virtual Servers', ''],
      gpu: ['GPU Servers', ''],
      dedicated: ['Dedicated Servers', ''],
      ssl: ['SSL', ''],
      domain: ['Domain', '']
    },
    doc: ['Documentation', 'Access Valuable Knowledgebase and Articles'],
    account: ['Account', 'Account Information'],
    account_vertifiy: ['Verification Information'],
    password: ['Change Password'],
    emails: ['Emails', 'View Email Records'],
    logout: ['Log Out'],
    login: ['Log In'],
    sign_up: ['Sign Up'],
    app_overview: ['Overview', 'General settings and information'],
    app_db_user: ['DB & User', 'Manage your databases and users'],
    app_topic_user: ['Topic & User', 'Manage your topics and users'],
    app_site_ssl: ['Site & SSL', 'Manage your websites, DNS, SSL, Domain and URL redirection'],
    app_deployment: ['Deployment', 'Manage your websites, DNS, SSL, Domain and URL redirection'],
    app_security: ['Security', 'Manage your {0}’s security setting'],
    app_php_admin: ['phpMyAdmin', 'Manage MySQL/MariaDB'],
    app_domain_dns: ['NS & DNS', 'Manage your Nameservers and DNS records'],
    app_kibana: ['Kibana', 'Manage Elasticsearch'],
    app_parameters: ['Parameters', 'Manage SQL Config File'],
    app_file_manager: ['File Manager', 'Manage the {0} files and folders.'],
    app_shell_ssh: ['Shell/SSH', 'Manage your {0}s via command line interface'],
    app_ftps: [
      'FTPS Server',
      'Encrypt data transfer between your local machine and the {0} server'
    ],
    app_metrics: ['Metrics', 'Check your {0}s’ resource usage'],
    app_snapshots: ['Snapshots', 'Manage data backups and restorations'],
    app_events: ['Events', 'Check the history of {0} management operations'],
    app_guidance: ['Guidance', 'Get step-by-step instructions on {0} management'],
    vps_server: ['Server Management', 'Manage your server'],
    add_software: ['Additional Software', 'Add additional software to your server'],
    billing_information: ['Billing Information'],
    billing_invoices: ['Invoices'],
    billing_payment: ['Payment Methods'],
    billing_credits: ['Credits']
  },
  user: {
    first_name: 'First Name',
    last_name: 'Last Name',
    country_region: 'Country / Region',
    email: 'Email',
    alt_email: 'Alternative Email',
    state: 'State',
    city: 'City',
    address_1: 'Address',
    address_2: 'Address 2',
    postcode: 'Postcode',
    zipcode: 'ZIP Code',
    phone_cc: 'CC Number',
    phone_number: 'Phone Number',
    address: 'Address',
    company: 'Company',
    web_site: 'Web Site',
    google_plus: 'Google+',
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'Linkedin',
    job_description: 'Job Description',
    fax_no: 'Fax Number',
    name: 'Name',
    epp_code: 'EPP Code',
    purpose: 'Purpose',
    category: 'Category'
  },
  attrs: {
    all: 'All',
    subject: 'Subject',
    department: 'Department',
    to: 'TO',
    cc: 'CC',
    time_zone: 'Time Zone',
    instance: 'Instances',
    instance_cpu: 'CPU',
    instance_type: 'Type',
    instance_status: 'Status',
    plan_cpu: 'CPU Cores',
    plan_memory: 'Memory',
    plan_disk: 'Disk',
    plan_backup: 'Backup Storage',
    plan_replica: 'Replica',
    plan_os: 'OS',
    plan_ver: 'Version',
    plan_jdk_ver: 'JDK Version',
    plan_spr_ver: 'Spring Boot Version',
    plan_component: 'Component',
    plan_location: 'Location',
    plan_price: 'Price',
    plan_due: 'Due date',
    plan_gpu_model: 'GPU Model',
    plan_bandwidth: 'Bandwidth',
    plan_gpu_card: 'GPU Card',
    plan_dedicated_ip: 'Dedicated IP',
    plan_access_port: 'Access Port',
    plan_discount: 'Plan Exclusive Discount',
    billing_cycle: 'Billing Cycle',
    billing_method: 'Payment Method',
    billing_promotion_code: 'Promotion Code',
    billing_sum: 'Summary',
    billing_plan: 'Plan',
    billing_discount: 'Discount | Discount | Discount({n})',
    billing_tax: 'Tax',
    billing_total: 'Total',
    plan_setup_fee: 'Plan Setup Fee',
    addon_setup_fee: 'Addon Setup Fee',
    billing_today_total: 'Total Due Today',
    billing_sub_total: 'Sub Total',
    billing_credit: 'Credits',
    configuration: 'Configuration',
    app_created: 'Created',
    app_remain: 'Remain',
    app_name: 'Product',
    app_termination: 'Termination Date',
    app_over_due: 'Over Due',
    app_backup_date: 'Backup Date',
    app_backup_size: 'Backup Size',
    app_cancel_reason: 'Cancel Reason',
    app_primary_ip: 'Primary IP',
    app_next_due: 'Next Due Date',
    app_reg_date: 'Reg Date',
    app_domain_name: 'Domain Name',
    app_domain_status: 'Domain Status',
    notes: 'Notes'
  },
  button: {
    reply: 'Reply',
    cancelReply: 'Cancel Reply',
    send: 'Send',
    confirm: 'Confirm',
    submit_trial_request: 'Submit Trial Request',
    cancel: 'Cancel',
    update: 'Update',
    submit: 'Submit',
    close: 'Close',
    remove: 'Remove',
    search: 'Search',
    clear: 'Clear',
    billing_cycle: 'Change Billing Cycle',
    attach_files: 'Attach files',
    new_app: 'New @.capitalize:common.prod',
    new_gpu: 'Order GPU Server',
    new_linux_vps: 'Order Virtual Server',
    new_gpu_vps: 'Order GPU Server',
    new_dedicated: 'Order Dedicated Server',
    new_domain: 'Order a New Domain',
    new_ssl: 'Order New SSL',
    new_ticket: 'Submit Ticket',
    new_feedback: 'Provide Feedback',
    deploy: 'Deploy Now',
    restore: 'Restore',
    details: 'Details',
    rename: 'Rename',
    manage: 'Manage',
    re_deploy: 'Re-deploy',
    pay_now: 'Pay Now',
    order_now: 'Order Now',
    renew: 'Renew',
    check_out: 'Check Out',
    back: 'Back',
    next: 'Next',
    install: 'Install',
    free_trial: 'Free Trial',
    online_chat: 'Online Chat',
    confirm_page: 'Confirm',
    skip: 'Skip'
  },
  rule: {
    required: 'Required',
    max_characters: 'Maximum {0} characters',
    min_characters: 'Minimum {0} characters',
    format_error: 'Invalid format, please re-input',
    phone_error: 'Invalid phone number.',
    server_name:
      'A hostname can only contain hyphens (-), period (.), a-z, A-Z, and 0-9, and can not begin and end with a hyphen',
    numberCharacter:
      'A combination of letters, numbers, and symbols (only symbols !@#$%^&*() are supported)',
    noNumOnly: 'Can not only contain numbers'
  },
  unit: {
    day: 'day | day(s)',
    month: 'm | month | {n} months',
    year: 'year | {n} year | {n} years',
    currency: '${0}',
    discount: '{0}%off'
  },
  status: {
    cancelled: 'Cancelled',
    deploy_failed: 'Deploy Failed',
    process: 'Processing',
    active: 'Active',
    pending: 'Pending',
    suspended: 'Suspended',
    pending_activation: 'Pending Activation',
    pending_removal: 'Pending Removal',
    addon_cancelled: 'Cancelled',
    unconfirmed: 'Unconfirmed'
  },
  runningStatus: {
    running: 'Running',
    failed: 'Failed',
    start_up: 'Startup',
    shut_off: 'Shut Off',
    paused: 'Paused',
    error: 'Error'
  },
  ipStatus: {
    pending: 'Pending',
    adding: 'Adding',
    deleting: 'Deleting',
    active: 'Active',
    addition_failed: 'AdditionFailed',
    deletion_failed: 'DeletionFailed',
    addition_timeout: 'AdditionTimeout',
    deletion_timeout: 'DeletionTimeout'
  },
  paidStatus: {
    unpaid: 'Unpaid',
    paid: 'Paid',
    cancelled: 'Cancelled',
    fully_refunded: 'Fully Refunded',
    partially_refunded: 'Partially Refunded',
    partially_paid: 'Partially Paid',
    draft: 'Draft',
    charged_back: 'Charged Back'
  },
  affStatus: {
    pending: 'Pending',
    invalid: 'Invalid',
    available: 'Available',
    withdrawing: 'Withdrawing',
    withdrawn: 'Withdrawn'
  },
  commissionStatus: {
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    cancelled: 'Cancelled'
  },
  payment_method: {
    credit_card: 'Credit Card',
    paypal: 'PayPal',
    credit: 'Credit Applied',
    ali_pay: 'AliPay',
    wechat_pay: 'WeChat Pay'
  },
  cancelReason: {
    overdue: 'Overdue On Payment',
    cancel_req: 'Cancellation Request',
    fail_verify: 'Failed Identity Verification',
    cancel_agent: 'Cancelled by Agent',
    suspend_agent: 'Suspended by Agent',
    closed: 'Account Closed',
    resource_abuse: 'Resource Abuse',
    resource_overflow: 'Resource Overflow'
  },
  departments: {
    billing: 'Billing Department',
    sales: 'Sales Department',
    support: 'Support Department',
    operation: 'Operation Department'
  },
  message: {
    install_tips: ['It may take some time to finish.'],
    error_common: ['Sorry! Something went wrong. Please contact us by ticket.'],
    error_deploy: ['Deploy failed. Please wait for manual deployment. It may take 2-4 hours.'],
    search_empty: [
      "It looks like there aren't matches for your search. {us} if you would like us to provide service for the product you searched.",
      'Contact us'
    ],
    change_billing_cycle: [
      'The billing cycle was updated successfully',
      'The billing cycle update failed ! Please try again later.'
    ],
    change_name: [
      'Your {name} was renamed successfully.',
      'Oops, {name} renaming failed, please contact us at {email} for this issue.'
    ],
    cancel_app: [
      'The cancellation request of your product has been deleted.',
      'Oops, your service cancellation failed. You can reach us at {email} for this issue.'
    ],
    paypal: [
      'After you update, the future recurring amount will be changed, followed by your PayPal subscription cancellation. If you still need automatic payment through PayPal, please click “PayPal Subscription” when you renew.',
      'Subscribed via PayPal'
    ]
  }
}
