var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageContent",
    { staticClass: "verify" },
    [
      _c("div", { attrs: { slot: "head-title" }, slot: "head-title" }, [
        _c("div", [
          _c("span", [_vm._v(_vm._s(_vm.$t("menu.account_vertifiy[0]")))]),
        ]),
      ]),
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              Object.keys(_vm.verifyInfo).length !== 0
                ? _c(
                    "v-col",
                    { staticClass: "mt-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-col", { attrs: { md: "12", sm: "12" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("verify_desc[0]")) +
                                    "\n            "
                                ),
                              ]),
                              _vm.verifyInfo.identity_enabled
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { md: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form_title" },
                                                [
                                                  _c("RequiredDot", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "form_labels[0]"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "form_desc",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("form_desc[0]")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "outlined" },
                                                [
                                                  _c(
                                                    "Upload",
                                                    {
                                                      ref: "uploadIdentify",
                                                      attrs: {
                                                        accept: "image/*",
                                                        postAction:
                                                          "/v1/upload_client_verification_file/",
                                                        multiple: false,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "files",
                                                            fn: function (ref) {
                                                              var files =
                                                                ref.files
                                                              return [
                                                                _c(
                                                                  "ul",
                                                                  _vm._l(
                                                                    files,
                                                                    function (
                                                                      file
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: file.name,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            [
                                                                              Math.floor(
                                                                                file.progress
                                                                              ) !==
                                                                              100
                                                                                ? _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          shrink:
                                                                                            "",
                                                                                          "d-flex":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-progress-circular",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              rotate: 360,
                                                                                              size: 50,
                                                                                              width: 4,
                                                                                              value:
                                                                                                file.progress,
                                                                                              color:
                                                                                                "success",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  " +
                                                                                              _vm._s(
                                                                                                file.progress
                                                                                              ) +
                                                                                              "\n                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              file.response &&
                                                                              file
                                                                                .response
                                                                                .data
                                                                                ? _c(
                                                                                    "v-flex",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "thumbnail",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-img",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width:
                                                                                                    "65",
                                                                                                  "aspect-ratio":
                                                                                                    "1",
                                                                                                  src:
                                                                                                    "/v1/media/" +
                                                                                                    file
                                                                                                      .response
                                                                                                      .data
                                                                                                      .file,
                                                                                                  alt: file
                                                                                                    .response
                                                                                                    .data
                                                                                                    .filename,
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.previewImg(
                                                                                                      "/v1/media/" +
                                                                                                        file
                                                                                                          .response
                                                                                                          .data
                                                                                                          .file
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        227354944
                                                      ),
                                                      model: {
                                                        value: _vm.identify,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.identify = $$v
                                                        },
                                                        expression: "identify",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none",
                                                          attrs: {
                                                            depressed: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t("btn")
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.verifyInfo
                                                    .identity_file_name &&
                                                  _vm.identify.length === 0
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("v-img", {
                                                              attrs: {
                                                                width: "65",
                                                                "aspect-ratio":
                                                                  "1",
                                                                src:
                                                                  "/v1/media/" +
                                                                  _vm.verifyInfo
                                                                    .identity_file_url,
                                                                alt: _vm
                                                                  .verifyInfo
                                                                  .identity_file_name,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewImg(
                                                                      "/v1/media/" +
                                                                        _vm
                                                                          .verifyInfo
                                                                          .identity_file_url
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumbnail-close",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#999",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clearFile(
                                                                "identity"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.verifyInfo.credit_card_enabled
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { md: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form_title" },
                                                [
                                                  _c("RequiredDot", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "form_labels[1]"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "form_desc",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("form_desc[1]")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "outlined" },
                                                [
                                                  _c(
                                                    "Upload",
                                                    {
                                                      ref: "creditUpload",
                                                      attrs: {
                                                        accept: "image/*",
                                                        postAction:
                                                          "/v1/upload_client_verification_file/",
                                                        multiple: false,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "files",
                                                            fn: function (ref) {
                                                              var files =
                                                                ref.files
                                                              return [
                                                                _c(
                                                                  "ul",
                                                                  _vm._l(
                                                                    files,
                                                                    function (
                                                                      file
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: file.name,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            [
                                                                              Math.floor(
                                                                                file.progress
                                                                              ) !==
                                                                              100
                                                                                ? _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          shrink:
                                                                                            "",
                                                                                          "d-flex":
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-progress-circular",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              rotate: 360,
                                                                                              size: 50,
                                                                                              width: 4,
                                                                                              value:
                                                                                                file.progress,
                                                                                              color:
                                                                                                "success",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  " +
                                                                                              _vm._s(
                                                                                                file.progress
                                                                                              ) +
                                                                                              "\n                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              file.response &&
                                                                              file
                                                                                .response
                                                                                .data
                                                                                ? _c(
                                                                                    "v-flex",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "thumbnail",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-img",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width:
                                                                                                    "65",
                                                                                                  "aspect-ratio":
                                                                                                    "1",
                                                                                                  src:
                                                                                                    "/v1/media/" +
                                                                                                    file
                                                                                                      .response
                                                                                                      .data
                                                                                                      .file,
                                                                                                  alt: file
                                                                                                    .response
                                                                                                    .data
                                                                                                    .filename,
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.previewImg(
                                                                                                      "/v1/media/" +
                                                                                                        file
                                                                                                          .response
                                                                                                          .data
                                                                                                          .file
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        227354944
                                                      ),
                                                      model: {
                                                        value: _vm.credit,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.credit = $$v
                                                        },
                                                        expression: "credit",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none",
                                                          attrs: {
                                                            depressed: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t("btn")
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.verifyInfo
                                                    .credit_card_file_name &&
                                                  _vm.credit.length === 0
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "default",
                                                          },
                                                          [
                                                            _c("v-img", {
                                                              attrs: {
                                                                width: "65",
                                                                "aspect-ratio":
                                                                  "1",
                                                                src:
                                                                  "/v1/media/" +
                                                                  _vm.verifyInfo
                                                                    .credit_card_file_url,
                                                                alt: _vm
                                                                  .verifyInfo
                                                                  .credit_card_file_name,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewImg(
                                                                      "/v1/media/" +
                                                                        _vm
                                                                          .verifyInfo
                                                                          .credit_card_file_url
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumbnail-close",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#999",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clearFile(
                                                                "creditcard"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.verifyInfo.social_account_enabled
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { md: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form_title" },
                                                [
                                                  _c("RequiredDot", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "form_labels[2]"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "form_desc",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("form_desc[2]")
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", sm: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "12",
                                                        sm: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formData
                                                              .social_account,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formData,
                                                              "social_account",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formData.social_account",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-col", { attrs: { md: "12", sm: "12" } }, [
                                _c("div", { staticClass: "font-weight-bold" }, [
                                  _vm._v(_vm._s(_vm.$t("notes_title"))),
                                ]),
                                _c(
                                  "ul",
                                  { staticStyle: { "list-style": "disc" } },
                                  [
                                    _c("li", [
                                      _vm._v(_vm._s(_vm.$t("notes[0]"))),
                                    ]),
                                    _c("li", [
                                      _vm._v(_vm._s(_vm.$t("notes[1]"))),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none text-right",
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.loading,
                                      },
                                      on: { click: _vm.submitVerificationInfo },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("button.submit")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticStyle: { position: "relative" } },
            [
              _c("v-img", {
                directives: [{ name: "lazy", rawName: "v-lazy" }],
                attrs: {
                  src: _vm.previewImgUrl,
                  contain: "",
                  hight: "100%",
                  "aspect-ratio": "1",
                },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }