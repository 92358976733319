<template>
  <div class="upload">
    <slot name="files" :files="files"></slot>
    <FileUpload
      v-bind="$attrs"
      :input-id="Math.random()"
      class="v-file-upload"
      :headers="{ Authorization: `Token ${token}` }"
      :post-action="postAction"
      :response="fileResponse"
      v-model="files"
      :multiple="multiple"
      @input="updatetValue"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload">
      <slot></slot>
    </FileUpload>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import cookie from '@/module/utils/cookie'

export default {
  name: 'upload',
  components: {
    FileUpload
  },
  data() {
    return {
      files: this.value,
      attachments: [],
      fileResponse: {}
    }
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    postAction: {
      type: String,
      default: '/v1/attachments/upload/'
    },
    multiple: {
      type: Boolean,
      default: true
    }
    // limitSize : {
    //     type : Object ,
    //     default(){
    //         return {
    //             size : 1024*1024*20 ,
    //             error : 'File size exceeds 20M'
    //         }
    //     }
    // }
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.files = val
      }
    },
    files: {
      deep: true,
      handler() {
        this.$emit('input', this.files)
      }
    }
  },
  computed: {
    token() {
      return cookie.get('token')
    }
  },
  methods: {
    updatetValue(value) {
      this.files = value
    },
    inputFilter(newFile, oldFile, prevent) {
      // add new file  or  update file
      if (newFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile(newFile, oldFile) {
      // if (newFile.active && !oldFile.active) {
      //     if (newFile.size >= 0 && newFile.size > this.limitSize.size) {
      //         this.$refs.upload.update(newFile, { error:  this.limitSize.error })
      //     }
      // }
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    getUploaded() {
      return this.$refs.upload.uploaded
    }
  },
  created() {
    this.files = []
  }
}
</script>

<style lang="scss">
.file-uploads label {
  cursor: pointer;
}
</style>
