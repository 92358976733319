export default {
  common: {
    app: '应用',
    prod: '产品',
    vps: '云服务器'
  },
  productType: {
    vm: '云服务器',
    app: '应用',
    bare_metal: '物理服务器'
  },
  menu: {
    home: ['概览', '面板概览'],
    apps: ['云应用', '查看所有订购的应用', '云应用列表'],
    affiliate: ['推广联盟', '推广联盟'],
    vps: ['云服务器', '已订购的云服务器列表', '云服务器列表'],
    ssl: ['SSL数字证书', 'SSL数字证书管理服务列表'],
    domain: ['域名', '域名列表'],
    dedicated: ['物理服务器', '已订购的物理服务器列表', '物理服务器列表'],
    gpu: ['GPU服务器', '已订购的GPU服务器列表', 'GPU服务器列表'],
    k8s: ['Kubernetes', 'Kubernetes集群列表'],
    billing: ['账单管理', '查看和管理账单'],
    tickets: ['工单管理', '提交工单获取24小时技术支持和服务'],
    dns: ['DNS管理', '管理DNS记录'],
    all: ['产品订购', '订购更多鹄望云产品和服务'],
    products: {
      app: ['云应用', ''],
      vps: ['云服务器', ''],
      gpu: ['GPU服务器', ''],
      dedicated: ['物理服务器', ''],
      k8s: ['Kubernets集群', ''],
      ssl: ['SSL', ''],
      domain: ['Domain', '']
    },
    doc: ['帮助文档', '获取产品使用指导和产品选购指南'],
    account: ['账户', '用户信息'],
    account_vertifiy: ['账户验证'],
    password: ['修改密码'],
    emails: ['邮件列表', '查看邮件列表'],
    logout: ['退出登录'],
    login: ['登录'],
    sign_up: ['注册'],
    app_overview: ['基本信息', '基本信息和基本设置'],
    app_db_user: ['数据库 & 用户', '管理数据库和数据库用户'],
    app_topic_user: ['数据库 & 用户', '管理数据库和数据库用户'],
    app_site_ssl: ['Site & SSL', '管理你的网站、DNS、SSL、域名和URL重定向'],
    app_deployment: ['部署', '管理你的网站、DNS、SSL、域名和URL重定向'],
    app_security: ['安全配置', '管理你的产品的安全设置'],
    app_php_admin: ['phpMyAdmin', '管理 MySQL/MariaDB'],
    app_domain_dns: ['NS & DNS', '管理域名服务器和DNS记录'],
    app_kibana: ['Kibana', '管理 Elasticsearch'],
    app_parameters: ['参数配置', '管理 SQL 配置文件'],
    app_file_manager: ['文件管理', '管理产品的文件和文件夹'],
    app_shell_ssh: ['Shell/SSH', '通过命令行界面管理你的产品'],
    app_ftps: ['FTPS 服务', '在本地计算机和产品服务器之间加密数据传输'],
    app_metrics: ['资源监控', '检查你的产品资源使用情况'],
    app_snapshots: ['备份', '管理数据备份和恢复'],
    app_events: ['日志', '查看产品管理操作的历史记录'],
    app_guidance: ['指导文档', '获取产品管理的逐步说明'],
    app_service_info: ['服务信息', '查看服务详情'],
    vps_server: ['服务器管理', '管理你的服务器'],
    add_software: ['附加软件', '为你的服务器添加附加软件'],
    billing_information: ['账户信息'],
    billing_invoices: ['账单列表'],
    billing_payment: ['支付方式'],
    billing_credits: ['账户余额']
  },
  user: {
    first_name: '姓名',
    last_name: '姓',
    country_region: '国家/地区',
    email: '邮箱',
    alt_email: '备用邮箱',
    state: '州/省份',
    city: '城市',
    address_1: '地址',
    address_2: '地址2',
    postcode: '邮政编码',
    zipcode: '邮政编码',
    phone_cc: '抄送号码',
    phone_number: '手机号码',
    address: '地址',
    company: '公司',
    web_site: '网站',
    google_plus: 'Google+',
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'Linkedin',
    job_description: '工作描述',
    fax_no: '传真号码',
    name: '姓名',
    epp_code: 'EPP代码',
    purpose: '用途',
    category: '分类'
  },
  attrs: {
    all: '全部',
    subject: '主题',
    department: '部门',
    to: '发送给',
    cc: '抄送',
    time_zone: '时区',
    instance: '实例',
    instance_cpu: 'CPU',
    instance_type: '类型',
    instance_status: '状态',
    plan_cpu: 'CPU核数',
    plan_memory: '内存',
    plan_disk: '磁盘',
    plan_backup: '备份空间',
    plan_replica: '副本数',
    plan_os: '操作系统',
    plan_ver: '版本',
    plan_jdk_ver: 'JDK版本',
    plan_spr_ver: 'Spring Boot版本',
    plan_component: '组件',
    plan_location: '节点',
    plan_price: '价格',
    plan_due: '到期时间',
    plan_gpu_model: 'GPU型号',
    plan_bandwidth: '带宽',
    plan_gpu_card: 'GPU卡',
    plan_dedicated_ip: 'IP 地址',
    plan_access_port: '端口',
    plan_discount: '套餐折扣',
    billing_cycle: '计费周期',
    billing_method: '支付方式',
    billing_promotion_code: '优惠码',
    billing_sum: '摘要',
    billing_plan: '套餐',
    billing_discount: '折扣 | 折扣 | 折扣({n})',
    billing_tax: '税',
    billing_total: '应付',
    plan_setup_fee: '产品安装费',
    addon_setup_fee: '额外硬件安装费',
    billing_today_total: '应付',
    billing_sub_total: '总价',
    billing_credit: '余额抵扣',
    configuration: '配置',
    app_created: '创建时间',
    app_remain: '剩余',
    app_name: '产品名称',
    app_termination: '终止时间',
    app_over_due: '过期',
    app_backup_date: '备份时间',
    app_backup_size: '备份大小',
    app_cancel_reason: '终止原因',
    app_primary_ip: 'IP地址',
    app_next_due: '到期时间',
    app_reg_date: '注册日期',
    app_domain_name: '域名',
    app_domain_status: '域名状态',
    quantity: '数量',
    notes: '备注',
    note: '备注'
  },
  button: {
    reply: '回复',
    cancelReply: '取消回复',
    send: '发送',
    confirm: '确认',
    submit_trial_request: '提交试用请求',
    cancel: '取消',
    update: '更新',
    submit: '提交',
    close: '关闭',
    remove: '删除',
    search: '搜索',
    clear: '清除',
    billing_cycle: '修改计费周期',
    attach_files: '上传附件',
    new_app: '订购产品',
    new_gpu: '订购GPU服务器',
    new_linux_vps: '订购云服务器',
    new_gpu_vps: '订购GPU云服务器',
    new_dedicated: '订购物理服务器',
    new_k8s: '订购 Kubernetes 集群',
    new_domain: '订购域名',
    new_ssl: '订购SSL',
    new_ticket: '提交工单',
    new_feedback: '提交反馈',
    deploy: '订购',
    restore: '恢复',
    details: '详情',
    rename: '重命名',
    manage: '管理',
    re_deploy: '重新部署',
    pay_now: '去支付',
    order_now: '去订购',
    renew: '续费',
    check_out: '结算',
    back: '上一步',
    next: '下一步',
    install: '安装',
    free_trial: '免费试用',
    online_chat: '在线咨询',
    confirm_page: '订单确认',
    skip: '跳过',
    out_of_stock: ' 缺货'
  },
  rule: {
    required: '必填',
    max_characters: '最大{0}个字符',
    min_characters: '最小{0}个字符',
    format_error: '格式错误, 请重新输入',
    phone_error: '无效的手机号码, 请重新输入',
    server_name:
      '主机名只能包含连字符（-）、句点（.）、小写字母a-z、大写字母A-Z和数字0-9，且不能以连字符开头和结尾',
    numberCharacter: '必须包含字母、数字和符号（仅支持符号 !@#$%^&*()）',
    noNumOnly: '不能只包含数字',
    cc_email_error: '请输入正确格式的一个或多个邮箱地址',
    c_n_8: '密码至少8位, 包含一个大写字母、一个小写字母和一个数字'
  },
  unit: {
    day: '天 | 天',
    month: '月 | 月 | {n}个月',
    year: '年 | 年 | {n}年',
    currency: '￥{0}',
    discount: '{0}折'
  },
  status: {
    cancelled: '已终止',
    deploy_failed: '部署失败',
    process: '进行中',
    active: '运行中',
    pending: '待处理',
    suspended: '已暂停',
    pending_activation: '待添加',
    pending_removal: '待删除',
    addon_cancelled: '已删除',
    unconfirmed: '待确认'
  },
  runningStatus: {
    running: '运行中',
    failed: '失败',
    start_up: '启动中',
    shut_off: '已关闭',
    paused: '已暂停',
    error: '发生错误'
  },
  ipStatus: {
    pending: '进行中',
    adding: '添加中',
    deleting: '删除中',
    active: '已生效',
    addition_failed: '添加失败',
    deletion_failed: '删除失败',
    addition_timeout: '添加超时',
    deletion_timeout: '删除超时'
  },
  paidStatus: {
    unpaid: '未支付',
    paid: '已支付',
    cancelled: '已取消',
    fully_refunded: '全额退款',
    partially_refunded: '部分退款',
    partially_paid: '部分支付',
    draft: '草稿',
    charged_back: '已退款'
  },
  affStatus: {
    pending: '等待中',
    invalid: '无效',
    available: '可提现',
    withdrawing: '提现中',
    withdrawn: '已提现'
  },
  commissionStatus: {
    pending: '等待中',
    approved: '审核通过',
    rejected: '审核失败',
    cancelled: '已取消'
  },
  payment_method: {
    credit_card: '信用卡',
    paypal: 'PayPal',
    credit: '积分',
    ali_pay: '支付宝',
    wechat_pay: '微信'
  },
  cancelReason: {
    overdue: '过期',
    cancel_req: '用户提交终止使用请求',
    fail_verify: '验证失败',
    cancel_agent: '被管理员终止',
    suspend_agent: '被管理员暂停',
    closed: '账号已关闭',
    resource_abuse: '非法使用',
    resource_overflow: '资源不足'
  },
  departments: {
    billing: '计费',
    sales: '销售',
    support: '技术',
    operation: '运维'
  },
  message: {
    install_tips: ['需要一些时间才能完成。', '支付成功，系统处理中，请耐心等待。'],
    error_common: ['抱歉！出了点问题，请通过工单与我们联系。'],
    error_deploy: ['部署失败。请等待手动部署。可能需要2-4小时'],
    search_empty: [
      '很抱歉，似乎没有找到与您搜索的产品相关的结果。如果您希望我们为您提供有关该产品的服务，请随时{us}',
      '联系我们'
    ],
    change_billing_cycle: ['计费周期变更成功', '计费周期变更失败, 请稍后再试.'],
    change_name: ['重命名成功', '重命名失败, 请稍后再试.'],
    cancel_app: [
      '您的终止使用请求已被撤回。',
      '抱歉，服务取消失败。您可以通过 {email} 联系我们解决这个问题。'
    ],
    paypal: [
      '在您更改之后，未来的支付金额将发生变化，随之会取消您的PayPal订阅。如果您仍需要通过PayPal进行自动支付，请在续费时“PayPal订阅”。',
      '已Paypal订阅'
    ]
  }
}
