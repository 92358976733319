<template>
  <div class="page-content">
    <div class="page-header-bar" :class="{ hidden: isHiddenNav }">
      <v-container
        fluid
        py-lg-0
        py-md-1
        px-2
        px-md-8
        px-lg-12
        px-xl-14
        :style="{ height: `${barHeight}px` }"
        class="page-container">
        <slot name="head">
          <v-row align-start justify-space-around fill-height>
            <v-col cols="12">
              <div @click="$router.go(-1)">
                <v-btn depressed icon color="dark-lighten4">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" class="pt-0 px-4">
              <v-layout justify-space-between align-center align-content-start>
                <v-flex shrink>
                  <span style="font-size: 1.38rem; font-weight: 600">
                    <slot name="head-title"></slot>
                  </span>
                </v-flex>
                <v-flex shrink>
                  <slot name="head-right"></slot>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </slot>
      </v-container>
    </div>
    <v-container
      fluid
      v-bind="containerProps"
      class="page-container"
      px-2
      px-md-8
      px-lg-12
      px-xl-14>
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PageContent',
  props: {
    dynamic: {
      type: Boolean,
      default: true
    },
    barHeight: {
      type: Number,
      default: 120
    },
    containerProps: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isHiddenNav: false,
      lastScrollTop: 50
    }
  },
  methods: {
    handleScroll() {
      const currentScrollTop = window.pageYOffset
      if (currentScrollTop > this.lastScrollTop) {
        this.isHiddenNav = true
        this.$emit('hiddenNav', true)
      } else if (currentScrollTop < this.lastScrollTop) {
        this.isHiddenNav = false
        this.$emit('hiddenNav', false)
      }
      // this.lastScrollTop = currentScrollTop
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  .page-header-bar {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    position: sticky;
    top: 50px;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .page-header-bar.hidden {
    opacity: 0;
    z-index: -10;
  }
  @media only screen and (max-width: 1264px) {
    .page-container {
      height: unset !important;
    }
  }
}
</style>
