var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "div",
        { staticClass: "page-header-bar", class: { hidden: _vm.isHiddenNav } },
        [
          _c(
            "v-container",
            {
              staticClass: "page-container",
              style: { height: _vm.barHeight + "px" },
              attrs: {
                fluid: "",
                "py-lg-0": "",
                "py-md-1": "",
                "px-2": "",
                "px-md-8": "",
                "px-lg-12": "",
                "px-xl-14": "",
              },
            },
            [
              _vm._t("head", [
                _c(
                  "v-row",
                  {
                    attrs: {
                      "align-start": "",
                      "justify-space-around": "",
                      "fill-height": "",
                    },
                  },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                icon: "",
                                color: "dark-lighten4",
                              },
                            },
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "pt-0 px-4", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "justify-space-between": "",
                              "align-center": "",
                              "align-content-start": "",
                            },
                          },
                          [
                            _c("v-flex", { attrs: { shrink: "" } }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "1.38rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._t("head-title")],
                                2
                              ),
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { shrink: "" } },
                              [_vm._t("head-right")],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-container",
        _vm._b(
          {
            staticClass: "page-container",
            attrs: {
              fluid: "",
              "px-2": "",
              "px-md-8": "",
              "px-lg-12": "",
              "px-xl-14": "",
            },
          },
          "v-container",
          _vm.containerProps,
          false
        ),
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }