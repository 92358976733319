import $http from '@/module/http'
import { perUlrMap } from '@/module/constant'
import Vue from 'vue'
import i18n from '../../i18n'

// const allProduct = {...products,...productsVPS}
let vList = null
let pDatabase = null
let pDBUsers = null
let pConnection = null

let getPerUrl = function (appInfo) {
  return perUlrMap[appInfo.category === 'GPU' ? 'GPU' : appInfo.product_type]
}
let getProductTypeName = function (productType) {
  switch (productType) {
    case 'APP':
      return 'applications'
    case 'VM':
      return 'VPS'
    case 'Bare Metal':
      return 'Bare Metal Server'
    case 'Custom':
      return 'Custom'
    case 'SSL':
      return 'SSL'
    case 'Domain':
      return 'Domain'
  }
}

const getAppMenus = function (rootState, appInfo) {
  let productTypeName = getProductTypeName(appInfo.product_type)
  let showFileManager,
    showPhpadmin,
    showGuid,
    showKibana,
    showPara,
    showSecurity,
    showSiteSSl,
    showDB

  if (appInfo.stacks.length !== 0 && appInfo.stacks[0].services.length !== 0) {
    if (appInfo.stacks[0].services[0].filebrowser_info.has_filebrowser) {
      showFileManager = true
    }
    if (appInfo.stacks[0].services[0].phpmyadmin_info.has_phpmyadmin) {
      showPhpadmin = true
    }
    if (['elasticsearch'].includes(appInfo.product_inner_name)) {
      showKibana = true
    }
    if (['elasticsearch', 'rabbitmq'].includes(appInfo.product_inner_name)) {
      showDB = true
    }
    if (['gitlab'].indexOf(appInfo.product_inner_name) === -1) {
      showSiteSSl = true
    }
    if (!['prometheus', 'phabricator'].includes(appInfo.product_inner_name)) {
      showSecurity = true
    }
    // if (rootState.client.clientInfo.permissions.web_shell) {
    //   showSSH = true
    // }
    if (['mongodb'].indexOf(appInfo.product_inner_name) === -1) {
      showGuid = true
    }
    if (['mysql', 'postgresql', 'mariadb'].indexOf(appInfo.product_inner_name) !== -1) {
      showPara = true
    }
  }

  let appArr = [
    {
      icon: '',
      text: 'menu.app_overview[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
      category: 'ALL',
      include: true,
      title: 'menu.app_overview[1]'
    },
    {
      icon: '',
      text: `${
        appInfo.category === 'Message Queue' ? 'menu.app_topic_user[0]' : 'menu.app_db_user[0]'
      }`,
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/dbuser`,
      category: ['Database', 'Message Queue'],
      include: !showDB,
      stepId: 'db-user',
      title: `${
        appInfo.category === 'Message Queue' ? 'menu.app_topic_user[1]' : 'menu.app_db_user[1]'
      }`
    },
    {
      icon: '',
      text: ['javapaas'].includes(appInfo.product_inner_name)
        ? 'menu.app_deployment[0]'
        : 'menu.app_site_ssl[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/domainSSL`,
      category: [
        'Ecom',
        'CMS',
        'Runtimes',
        'ERP',
        'Education',
        'AI',
        'DevOps',
        'EMR',
        'CRM',
        'Cloud Storage',
        'HRM',
        'Forum',
        'PM',
        'Wiki',
        'Email',
        'Productivity Tools',
        'Survey Tools',
        'Website Analytics',
        'Marketing Automation'
      ],
      include: showSiteSSl,
      stepId: 'site-ssl',
      title: 'menu.app_site_ssl[1]'
    },
    {
      icon: '',
      text: 'menu.app_security[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/security`,
      category: ['Database', 'Message Queue', 'DevOps'],
      include: showSecurity,
      title: i18n.t('menu.app_security[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_php_admin[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/php-admin`,
      category: 'ALL',
      include: showPhpadmin,
      title: 'menu.app_php_admin[1]'
    },
    {
      icon: '',
      text: 'menu.app_kibana[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/kibana`,
      category: 'ALL',
      include: showKibana,
      title: 'menu.app_kibana[1]'
    },
    {
      icon: '',
      text: 'menu.app_parameters[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/sql-config`,
      category: 'ALL',
      include: showPara,
      title: 'menu.app_parameters[1]'
    },
    {
      icon: '',
      text: 'menu.app_file_manager[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/file-manager`,
      category: 'ALL',
      include: showFileManager,
      title: i18n.t('menu.app_file_manager[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_shell_ssh[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/web-shell`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_shell_ssh[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_ftps[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/ftp-server`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_ftps[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_metrics[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/metrics`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_metrics[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_snapshots[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/snapshots`,
      category: 'ALL',
      include: true,
      title: 'menu.app_snapshots[1]'
    },
    {
      icon: '',
      text: 'menu.app_events[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/activities`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_events[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_guidance[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/guidance`,
      category: 'ALL',
      include: showGuid,
      stepId: 'guidance',
      title: i18n.t('menu.app_guidance[1]', [productTypeName])
    }
  ]
  return appArr
}
const getVBMenus = function (rootState, appInfo) {
  let productTypeName = getProductTypeName(appInfo.product_type)
  const isOnlyBilling = appInfo.only_billing_mgt
  let arr = [
    {
      icon: '',
      text: 'menu.app_overview[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
      category: 'ALL',
      include: true,
      title: 'menu.app_overview[1]'
    },
    {
      icon: '',
      text: 'menu.app_service_info[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/service-info`,
      category: 'ALL',
      include: isOnlyBilling,
      title: 'menu.app_service_info[1]'
    },
    {
      icon: '',
      text: 'menu.vps_server[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/server-manage`,
      category: 'ALL',
      include: !isOnlyBilling,
      title: 'menu.vps_server[1]'
    },
    {
      icon: '',
      text: 'menu.add_software[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/additional-software`,
      category: ['GPU', 'BareMetal'],
      include: !isOnlyBilling && appInfo.has_active_software_addon,
      title: 'menu.add_software[1]'
    },
    {
      icon: '',
      text: 'menu.app_events[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/events`,
      category: 'ALL',
      include: !isOnlyBilling,
      title: i18n.t('menu.app_events[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_guidance[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/guidance`,
      category: 'ALL',
      include: !isOnlyBilling,
      stepId: 'guidance',
      title: i18n.t('menu.app_guidance[1]', [productTypeName])
    }
  ]
  return arr
}
// const getVBMenus = function (rootState, appInfo) {
//   let productTypeName = getProductTypeName(appInfo.product_type)
//   let arr = [
//     {
//       icon: '',
//       text: 'menu.app_overview[0]',
//       path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
//       category: 'ALL',
//       include: true,
//       title: 'menu.app_overview[1]'
//     },
//     {
//       icon: '',
//       text: 'menu.vps_server[0]',
//       path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/server-manage`,
//       category: 'ALL',
//       include: true,
//       title: 'menu.vps_server[1]'
//     },
//     {
//       icon: '',
//       text: 'menu.add_software[0]',
//       path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/additional-software`,
//       category: ['GPU', 'BareMetal'],
//       include: appInfo.has_active_software_addon,
//       title: 'menu.add_software[1]'
//     },
//     {
//       icon: '',
//       text: 'menu.app_events[0]',
//       path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/events`,
//       category: 'ALL',
//       include: true,
//       title: i18n.t('menu.app_events[1]', [productTypeName])
//     },
//     {
//       icon: '',
//       text: 'menu.app_guidance[0]',
//       path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/guidance`,
//       category: 'ALL',
//       include: true,
//       stepId: 'guidance',
//       title: i18n.t('menu.app_guidance[1]', [productTypeName])
//     }
//   ]
//   return arr
// }
const getCustomMenus = function (rootState, appInfo) {
  let arr = [
    {
      icon: '',
      text: 'menu.app_overview[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
      category: 'ALL',
      include: true,
      title: 'menu.app_overview[1]'
    },
    {
      icon: '',
      text: 'menu.app_service_info[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/service-info`,
      category: 'ALL',
      include: true,
      title: 'menu.app_service_info[1]'
    }
  ]
  return arr
}
const getSSLMenus = function (rootState, appInfo) {
  let productTypeName = getProductTypeName(appInfo.product_type)
  let arr = [
    {
      icon: '',
      text: 'menu.app_overview[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
      category: 'ALL',
      include: true,
      title: 'menu.app_overview[1]'
    },
    {
      icon: '',
      text: 'menu.app_events[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/events`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_events[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_guidance[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/guidance`,
      category: 'ALL',
      include: true,
      stepId: 'guidance',
      title: i18n.t('menu.app_guidance[1]', [productTypeName])
    }
  ]
  return arr
}
const getDomainMenus = function (rootState, appInfo) {
  let productTypeName = getProductTypeName(appInfo.product_type)
  let arr = [
    {
      icon: '',
      text: 'menu.app_overview[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/overview`,
      category: 'ALL',
      include: true,
      title: 'menu.app_overview[1]'
    },
    {
      icon: '',
      text: 'menu.app_domain_dns[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/dns`,
      category: 'ALL',
      include:
        appInfo.third_party_domain &&
        appInfo.third_party_domain.length > 0 &&
        appInfo.third_party_domain[0].status === 'Active',
      title: 'menu.app_domain_dns[1]'
    },
    {
      icon: '',
      text: 'menu.app_events[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/events`,
      category: 'ALL',
      include: true,
      title: i18n.t('menu.app_events[1]', [productTypeName])
    },
    {
      icon: '',
      text: 'menu.app_guidance[0]',
      path: `/${getPerUrl(appInfo)}/${appInfo.uuid}/guidance`,
      category: 'ALL',
      include: true,
      stepId: 'guidance',
      title: i18n.t('menu.app_guidance[1]', [productTypeName])
    }
  ]
  return arr
}

export default {
  strict: true,
  namespaced: true,
  state: {
    products: [],
    productTagMap: {},
    productShowTag: {},
    productsExt: [],
    applicationList: [],
    appInfo: {},
    deploymentInfo: {},
    stats: {},
    dataBases: [],
    showGuidance: false,
    databaseDetail: {},
    users: [],
    siteList: [],
    availablePackages: []
  },
  getters: {
    activeProducts(state) {
      return state.products.filter((o) => o.active)
    },
    billingMonth() {
      return (duration) => {
        switch (duration) {
          case 1:
            return i18n.tc('unit.month', 0)
          case 3:
            return i18n.tc('unit.month', 3, { n: 3 })
          case 6:
            return i18n.tc('unit.month', 6, { n: 6 })
          case 12:
            return i18n.tc('unit.year', 0)
          case 24:
            return i18n.tc('unit.year', 2, { n: 2 })
          case 36:
            return i18n.tc('unit.year', 3, { n: 3 })
          case 48:
            return i18n.tc('unit.year', 4, { n: 4 })
          case 60:
            return i18n.tc('unit.year', 5, { n: 5 })
        }
      }
    },
    getPackageStatus() {
      return (item) => {
        // package initial status
        if (item.initial_status === 'Processing') {
          return 'initial'
        }
        if (item.status === 'Cancelled') {
          return 'Cancelled'
        } else if (['Failure', 'Timeout', 'Undeployed'].indexOf(item.deploy_status) !== -1) {
          return 'Deploy Failed'
        } else if (['Deploying', 'Deleting'].indexOf(item.deploy_status) !== -1) {
          return 'process'
        } else if (item.status === 'Active' && item.deploy_status === 'Success') {
          return 'Active'
        } else {
          return item.status
        }
      }
    },
    getProductNotext(state) {
      return (name) => {
        let prod = state.products.find((o) => o.product_name === name)
        return prod ? prod.image_100 : ''
      }
    },
    getServiceId() {
      return (appInfo) => {
        switch (appInfo.product_type) {
          case 'APP':
            return appInfo.stacks.length === 0 || appInfo.stacks[0].services.length === 0
              ? ''
              : appInfo.stacks[0].services[0].uuid
          case 'VM':
            return appInfo.vps.length ? appInfo.vps[0].uuid : ''
          case 'SSL':
            return appInfo.ssl.length ? appInfo.ssl[0].uuid : ''
          case 'Domain':
            return appInfo.third_party_domain.length ? appInfo.third_party_domain[0].uuid : ''
          case 'Custom':
            return appInfo.uuid ? appInfo.uuid : ''
          default:
            return appInfo.baremetal_server[0].uuid
        }
      }
    },
    getService(state) {
      return (idx = 0) => {
        if (state.appInfo.stacks.length === 0 || state.appInfo.stacks[0].services.length === 0) {
          return {}
        }
        return state.appInfo.stacks[0].services[idx]
      }
    },
    getFeaturesByFilter(state) {
      return (productName, filterArr = [], isExclude = false) => {
        let items = state.productsExt
        if (items.length) {
          return items.filter((o) => {
            let flag = true
            if (filterArr.length) {
              flag = isExclude
                ? !filterArr.includes(o.inner_name)
                : filterArr.includes(o.inner_name)
            }
            return o.product.inner_name === productName && flag
          })
        } else {
          return []
        }
      }
    },
    getCategoryMenus(state, getters, rootState) {
      return (appInfo) => {
        let handerMap = {
          APP: getAppMenus,
          VM: getVBMenus,
          'Bare Metal': getVBMenus,
          Custom: getCustomMenus,
          SSL: getSSLMenus,
          Domain: getDomainMenus
        }
        let finalArr = handerMap[appInfo.product_type](rootState, appInfo)
        return finalArr.filter((o) => {
          return (o.category === 'ALL' || o.category.indexOf(appInfo.category) !== -1) && o.include
        })
      }
    }
  },
  mutations: {
    setApplicationList(state, payload) {
      state.applicationList = payload
    },
    setDedicatedServersList(state, payload) {
      state.dedicatedServersList = payload
    },

    setSiteList(state, payload) {
      state.siteList = payload
    }
  },
  actions: {
    getProducts: ({ commit, dispatch }) => {
      return dispatch('getCmsInfo', ['products', 'tag', 'tag_group'], { root: true }).then(
        ({ results }) => {
          let [products, productTagMap, productShowTag] = results.map((item) => item.content_items)
          products = products.map((o) => o.detail)
          // const excludeProduct = ['javaclusters']
          productTagMap = productTagMap ? productTagMap[0].detail : []
          productShowTag = productShowTag ? productShowTag[0].detail : {}
          commit(
            'setState',
            {
              module: 'application',
              payload: {
                products,
                productTagMap,
                productShowTag
              }
            },
            { root: true }
          )
        }
      )
    },
    getProductFeatures: ({ commit }, { productName }) => {
      return $http
        .get('/product_features/', {
          params: {
            q: `product__inner_name=${productName},status=Active`
          }
        })
        .then((res) => {
          commit(
            'setState',
            {
              module: 'application',
              payload: {
                productsExt: res.results
              }
            },
            { root: true }
          )
          return res.results
        })
    },
    // getProductsByName: ({commit}, {productName}) => {
    //     return $http.get('/product_features/', {
    //         params: {
    //             q: `product__inner_name=${productName}`
    //         }
    //     }).then((res) => {
    //         commit('setState', {
    //             module: 'application',
    //             payload: {
    //                 productsExt: res.results
    //             }
    //         }, {root: true})
    //         return res.results
    //     })
    // },
    getPackageNumByQueryStr: (_, queryStr = null) => {
      return $http
        .get('/packages/statistics/', {
          params: { q: queryStr }
        })
        .then((res) => {
          return res
        })
    },
    getApplicationList: (
      { rootState },
      { page, page_size, q } = {
        page: 1,
        page_size: 30,
        q: ''
      }
    ) => {
      return $http
        .get('/packages/', {
          params: {
            q: `client=${rootState.client.clientInfo.id},product__type=APP${q ? ',' + q : ''}`,
            page,
            page_size
          }
        })
        .then((res) => {
          // commit(
          //   'setState',
          //   {
          //     module: 'application',
          //     payload: {
          //       applicationList: res.results
          //     }
          //   },
          //   { root: true }
          // )
          return res
        })
    },
    getVPSList: (
      { rootState },
      { page, page_size, q } = {
        page: 1,
        page_size: 30,
        q: ''
      }
    ) => {
      return $http
        .get('/packages/', {
          params: {
            q: `client=${rootState.client.clientInfo.id},product__type=VM,^product__category=GPU${
              q ? ',' + q : ''
            }`,
            page,
            page_size
          }
        })
        .then((res) => {
          return res
        })
    },
    getSSLList: ({ rootState, commit }, forceRefresh) => {
      if (!vList || forceRefresh) {
        vList = $http
          .get('/packages/', {
            params: {
              q: `client=${rootState.client.clientInfo.id},status__in=Active|Pending|Suspended|Cancelled|Unconfirmed,product__type=SSL`,
              page_size: 999
            }
          })
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  applicationList: res.results
                }
              },
              { root: true }
            )
            return res.results
          })
      }
      return vList
    },
    getDomainList: ({ rootState, commit }, forceRefresh) => {
      if (!vList || forceRefresh) {
        vList = $http
          .get('/packages/', {
            params: {
              q: `client=${rootState.client.clientInfo.id},status__in=Active|Pending|Suspended|Cancelled|Unconfirmed,product__type=Domain`,
              page_size: 999
            }
          })
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  applicationList: res.results
                }
              },
              { root: true }
            )
            return res.results
          })
      }
      return vList
    },
    getGPUList: (
      { rootState },
      { page, page_size, q } = {
        page: 1,
        page_size: 30,
        q: ''
      }
    ) => {
      return $http
        .get('/packages/', {
          params: {
            q: `client=${rootState.client.clientInfo.id},product__category=GPU${q ? ',' + q : ''}`,
            page,
            page_size
          }
        })
        .then((res) => {
          return res
        })
    },
    getDedicatedServersList: (
      { rootState },
      { page, page_size, q } = {
        page: 1,
        page_size: 30,
        q: ''
      }
    ) => {
      return $http
        .get('/packages/', {
          params: {
            q: `client=${
              rootState.client.clientInfo.id
            },product__type=Bare Metal,^product__category=GPU${q ? ',' + q : ''}`,
            page,
            page_size
          }
        })
        .then((res) => {
          return res
        })
    },
    getCustomList: (
      { rootState },
      { page, page_size, q } = {
        page: 1,
        page_size: 30,
        q: ''
      }
    ) => {
      return $http
        .get('/packages/', {
          params: {
            q: `client=${rootState.client.clientInfo.id},product__type=Custom${q ? ',' + q : ''}`,
            page,
            page_size
          }
        })
        .then((res) => {
          return res
        })
    },
    getAllPackages: () => {
      return $http.get('/packages/simplify/').then((res) => {
        return res
      })
    },

    getMaskStatus({ state, getters }) {
      let params = {
        type: state.appInfo.product_type,
        uuid: getters.getServiceId(state.appInfo)
      }
      return $http.get(`/packages/should_mask/`, {
        params
      })
    },
    getAppStats: ({ commit, state, getters }, forceRefresh) => {
      if (forceRefresh) {
        let urlMap = {
          APP: `/running-status/?service_uuid=${getters.getServiceId(state.appInfo)}`,
          VM: `/vps/${getters.getServiceId(state.appInfo)}/running-status/`,
          'Bare Metal': `/baremetal_servers/${getters.getServiceId(state.appInfo)}/running-status/`
        }
        // console.log(commit, state, getters, urlMap)
        // return Promise.resolve()
        return $http
          .get(urlMap[state.appInfo.product_type])
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  stats: res
                }
              },
              { root: true }
            )
            return res
          })
          .catch((e) => {
            Vue.prototype.$message.error({
              content: e.detail,
              duration: 0,
              closable: true
            })
            return Promise.reject(e)
          })
      } else {
        return Promise.resolve(state.stats)
      }
    },
    getAppDetailById({ commit }, { appId, isReset }) {
      if (isReset) {
        pDatabase = null
        pDBUsers = null
        pConnection = null
        commit(
          'setState',
          {
            module: 'application',
            payload: {
              deploymentInfo: {},
              stats: {},
              dataBases: [],
              databaseDetail: {},
              users: []
            }
          },
          { root: true }
        )
      }
      return $http.get(`/packages/${appId}/`).then((res) => {
        commit(
          'setState',
          {
            module: 'application',
            payload: {
              appInfo: res
            }
          },
          { root: true }
        )
        return res
      })
    },
    getConnectionInfo({ commit, state, getters }, forceRefresh) {
      if (!pConnection || forceRefresh) {
        pConnection = $http
          .get(`/deployment-info/`, {
            params: {
              service_uuid: getters.getServiceId(state.appInfo)
            }
          })
          .then((info) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  deploymentInfo: info
                }
              },
              { root: true }
            )
            return info
          })
          .catch((e) => {
            Vue.prototype.$message.error(e.detail)
            return Promise.reject(e)
          })
      }
      return pConnection
    },
    getDataBaseList({ commit, state, getters }, forceRefresh) {
      if (!pDatabase || forceRefresh) {
        pDatabase = $http
          .get(`/databases/`, {
            params: {
              service_uuid: getters.getServiceId(state.appInfo),
              page_size: 999
            }
          })
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  dataBases: res.results
                }
              },
              { root: true }
            )
            return res
          })
          .catch((e) => {
            Vue.prototype.$message.error({
              content: e.detail,
              duration: 0,
              closable: true
            })
            return Promise.reject(e)
          })
      }
      return pDatabase
    },
    getDataBaseUserList({ commit, state, getters }, forceRefresh) {
      if (!pDBUsers || forceRefresh) {
        pDBUsers = $http
          .get(`/db-users/`, {
            params: {
              service_uuid: getters.getServiceId(state.appInfo)
            }
          })
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  users: res.results
                }
              },
              { root: true }
            )
            return res
          })
          .catch((e) => {
            Vue.prototype.$message.error(e.detail)
            return Promise.reject(e)
          })
      }
      return pDBUsers
    },
    getDataBaseDetail({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/databases/${uuid}/`)
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  databaseDetail: res
                }
              },
              { root: true }
            )
            resolve(res)
          })
          .catch((e) => {
            // Vue.prototype.$message.error('Oops, something bad happened while retrieving database information, please contact us at support@cloudclusters.io for this issue.')
            Vue.prototype.$message.error(e.detail)
            reject(e)
          })
      })
    },
    getCurrentAddons({ state }) {
      return $http.get(`/packages/${state.appInfo.uuid}/already_addons_amount/`)
    },
    getAvailablePackages({ commit, rootState }) {
      if (rootState.oAuth.clientId === -1) {
        return Promise.resolve()
      } else {
        return $http
          .get(`clients/${rootState.client.clientInfo.id}/available_packages/`)
          .then((res) => {
            commit(
              'setState',
              {
                module: 'application',
                payload: {
                  availablePackages: res
                }
              },
              { root: true }
            )
            return res
          })
          .catch((e) => {
            Vue.prototype.$message.error(e.detail)
            return Promise.reject(e)
          })
      }
    },
    getProductDiscount({ rootState }, productInfo) {
      return new Promise((resolve, reject) => {
        $http
          .get(
            `${
              rootState.client.clientInfo.id === -1
                ? '/orders/recurring_amount_noauth/'
                : '/orders/recurring_amount/'
            }`,
            {
              params: productInfo
            }
          )
          .then((res) => {
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}
