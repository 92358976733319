import i18n from '../../i18n'

export default {
  required: (value) =>
    typeof value === 'string'
      ? !!value.trim() || i18n.t('rule.required')
      : !!value || i18n.t('rule.required'),
  arrayRequired: (value) => value.length !== 0 || i18n.t('rule.required'),
  lessCounter: (value) => !value || value.length <= 10 || i18n.t('rule.max_characters', [10]),
  counter32: (value) => !value || value.length <= 32 || i18n.t('rule.max_characters', [32]),
  noNumOnly: (value) => {
    const pattern = /^\d+$/
    return !value || !pattern.test(value) || i18n.t('rule.noNumOnly')
  },
  n_or_c: (value) => {
    const pattern = /^[a-z0-9]+$/
    return !value || pattern.test(value) || 'Can only contain lowercase characters and numbers'
  },
  characterStart: (value) => {
    const pattern = /^[a-zA-Z]/
    return !value || pattern.test(value) || 'Can only start with a character'
  },
  moreCounter: (value) => !value || value.length >= 8 || i18n.t('rule.min_characters', [8]),
  moreCounterTen: (value) => !value || value.length >= 10 || i18n.t('rule.min_characters', [10]),
  moreCounterSixteen: (value) =>
    !value || value.length >= 16 || i18n.t('rule.min_characters', [16]),
  n_and_c: (value) => {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])/
    return !value || pattern.test(value) || 'Must contain both characters and numbers'
  },
  n_c_C: (value) => {
    const pattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!#%&()*+,-./:;<=>?@[\]^_{|}~$]*$/
    return (
      !value ||
      pattern.test(value) ||
      'Must Contain both uppercase and lowercase letters and numbers'
    )
  },
  numberCharacter: (value) => {
    const pattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]*$/
    return !value || pattern.test(value) || i18n.t('rule.numberCharacter')
  },
  serverName: (value) => {
    // const pattern = /^(?![-.])[\w.-]*[A-Za-z0-9][\w.-]*(?<![-.])$/
    const pattern = /^(?![-.])([\w.-]*[A-Za-z0-9][\w.-]*)$/
    return !value || pattern.test(value) || i18n.t('rule.server_name')
  },
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !value || pattern.test(value) || i18n.t('rule.format_error')
  },
  ccEmail: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value) return true // allow empty
    const emails = value.split(',').map((email) => email.trim()) // 按逗号分割并去除空格
    for (const email of emails) {
      if (!pattern.test(email)) {
        return 'One or more emails are invalid.'
      }
    }
    return true
  },
  phone: (value) => {
    const pattern = /^\d{4,12}$/
    return !value || pattern.test(value) || i18n.t('rule.phone_error')
  },
  cn_phone: (value) => {
    const pattern = /^1[3-9]\d{9}$/
    return !value || pattern.test(value) || i18n.t('rule.format_error')
  },
  url: (value) => {
    const pattern =
      /^((https|http)?:\/\/)?(([0-9a-z_!~*'().&amp;=+$%-]+: )?[0-9a-z_!~*'().&amp;=+$%-]+@)?((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]$)|([0-9a-z_!~*'()-]+\.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.[a-z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z_!~*'().;?:@&amp;=+$,%#-]+)+\/?)$/
    return !value || pattern.test(value) || i18n.t('rule.format_error')
  },
  domain: (value) => {
    const pattern =
      /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
    return (
      !value || pattern.test(value) || 'Domain name is invalid, please re-input.(e.g., example.com)'
    )
  },
  // 严格的域名校验
  strictDomain: (value) => {
    const domainPattern =
      /^(?=^.{3,255}$)[a-zA-Z0-9]([-a-zA-Z0-9]{0,62}[a-zA-Z0-9])?(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
    const ipv6Pattern = /([a-f0-9:]+:+)+[a-f0-9]+/i // 排除ipv6地址
    const numericPattern = /^[0-9.]+$/ // 排除只由数字和点组成的输入,防止输入IP
    return (
      !value ||
      (domainPattern.test(value) && !ipv6Pattern.test(value) && !numericPattern.test(value)) ||
      'Domain name is invalid, please re-input.(e.g., example.com)'
    )
  },
  nameServer: (value) => {
    const pattern =
      /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
    return !value || pattern.test(value) || 'Name server is invalid'
  },
  domainName: (value) => {
    const pattern = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/
    return !value || pattern.test(value) || 'Domain name is invalid'
  },
  // domain with wildcard(the first and second character can be *., and the first . front content can be -)
  domainWildcard: (value) => {
    const pattern = /^(\*\.)?([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*\.)+[a-zA-Z0-9]+$/
    return !value || pattern.test(value) || 'Domain name is invalid'
  },
  excludeIP: (value) => {
    const pattern =
      /^(?!([0-9]{1,3}\.){3}[0-9]{1,3}$)(?!(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4})$)(?!(([0-9a-fA-F]{1,4}:){6}(:[0-9a-fA-F]{1,4}){1,2})$)(?!(([0-9a-fA-F]{1,4}:){5}((:[0-9a-fA-F]{1,4}){1,3})|([0-9]{1,3}\.){3}[0-9]{1,3})$)(?!(([0-9a-fA-F]{1,4}:){4}((:[0-9a-fA-F]{1,4}){1,4})|(([0-9a-fA-F]{1,4}:){3}[0-9a-fA-F]{1,4}:(:[0-9a-fA-F]{1,4}){1,3})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$)(?!(([0-9a-fA-F]{1,4}:){2}((:[0-9a-fA-F]{1,4}){1,5})|(([0-9a-fA-F]{1,4}:){1}[0-9a-fA-F]{1,4}:(:[0-9a-fA-F]{1,4}){1,5})|([0-9]{1,3}\.){3}[0-9]{1,3})$)(?!([0-9a-fA-F]{1,4}::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$)(?!([0-9a-fA-F]{1,4}:){0,5}:(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)).*$/
    return (
      !value || pattern.test(value) || 'Domain name is invalid, please re-input.(e.g., example.com)'
    )
  },
  includeSpecialCharacters: (value) => {
    const pattern = /^[a-zA-Z0-9-*.]+$/
    return (
      !value || pattern.test(value) || 'Domain name is invalid, please re-input.(e.g., example.com)'
    )
  },
  ip: (value) => {
    const pattern =
      /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
    return !value || pattern.test(value) || i18n.t('rule.format_error')
  },
  ipCidr: (value) => {
    const pattern =
      /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$|^(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([1-9]|[1-2]\d|3[0-2])$/
    return !value || pattern.test(value) || i18n.t('rule.format_error')
  },
  number0: (value) => {
    const pattern = /^[1-9]\d*|0$/
    return pattern.test(value) || 'Please set the priority to any positive integer or zero.'
  },
  notSpecialSymbol(pattern = /^(?=[0-9a-zA-Z*.]+$)/) {
    return (value) => {
      return !value || pattern.test(value) || i18n.t('rule.format_error')
    }
  },
  maxCounter(num = 30) {
    return (value) => !value || value.length <= num || i18n.t('rule.max_characters', [num])
  },
  minCounter(num = 8) {
    return (value) => !value || value.length >= num || i18n.t('rule.min_characters', [num])
  },
  port(value) {
    const pattern =
      /^(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|[1-9])$/
    return !value || pattern.test(value) || 'Port should be between 1 and 65535'
  },
  pathRule: (value) => {
    // path must start with /
    return !value || value.startsWith('/') || 'Path must start with /'
  },
  // Cannot contain spaces
  noSpace: (value) => {
    const pattern = /^\S+$/
    return !value || pattern.test(value) || 'Cannot contain spaces'
  },
  // cannot be empty
  notEmpty: (value) => {
    return !!value.trim() || 'Cannot be empty'
  },
  // cannot contain < and >
  canNotHTMLTag: (value) => {
    return /^[^<>]*$/.test(value) || 'Cannot contain < or >'
  },
  ipReg: (value) => {
    const pattern =
      /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
    return !value || pattern.test(value)
  },
  // can not only letter
  canNotOnlyLetter: (value) => {
    return !/^[a-zA-Z]*$/.test(value) || 'Can not only contain letters'
  },
  // ip or domain
  ipOrDomain: (value) => {
    const pattern =
      /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$|^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
    return !value || pattern.test(value) || 'Please enter a valid IP address or domain name'
  },
  n_l_c: (value) => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    return (
      !value ||
      pattern.test(value) ||
      'Must contain numbers, uppercase letters, lowercase letters, and special characters.'
    )
  }
}
