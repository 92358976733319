var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _vm._t("files", null, { files: _vm.files }),
      _c(
        "FileUpload",
        _vm._b(
          {
            ref: "upload",
            staticClass: "v-file-upload",
            attrs: {
              "input-id": Math.random(),
              headers: { Authorization: "Token " + _vm.token },
              "post-action": _vm.postAction,
              response: _vm.fileResponse,
              multiple: _vm.multiple,
            },
            on: {
              input: _vm.updatetValue,
              "input-filter": _vm.inputFilter,
              "input-file": _vm.inputFile,
            },
            model: {
              value: _vm.files,
              callback: function ($$v) {
                _vm.files = $$v
              },
              expression: "files",
            },
          },
          "FileUpload",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }