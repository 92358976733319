<template>
  <PageContent class="verify">
    <div slot="head-title">
      <div>
        <span>{{ $t('menu.account_vertifiy[0]') }}</span>
      </div>
    </div>
    <v-form ref="form">
      <v-row no-gutters>
        <v-col cols="12" class="mt-4" v-if="Object.keys(verifyInfo).length !== 0">
          <v-card class="elevation-0">
            <v-card-text>
              <v-col md="12" sm="12">
                {{ $t('verify_desc[0]') }}
              </v-col>
              <v-col md="12" sm="12" class="pb-0" v-if="verifyInfo.identity_enabled">
                <v-row no-gutters>
                  <v-col md="12" sm="12">
                    <div class="form_title">
                      <RequiredDot>
                        {{ $t('form_labels[0]') }}
                      </RequiredDot>
                      <div>
                        <v-tooltip top color="primary" max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on">info</v-icon>
                          </template>
                          <span>{{ $t('form_desc[0]') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="12" sm="12">
                    <div class="outlined">
                      <Upload
                        ref="uploadIdentify"
                        v-model="identify"
                        accept="image/*"
                        :postAction="'/v1/upload_client_verification_file/'"
                        :multiple="false">
                        <template v-slot:files="{ files }">
                          <ul>
                            <li v-for="file in files" :key="file.name">
                              <v-layout>
                                <v-flex shrink d-flex v-if="Math.floor(file.progress) !== 100">
                                  <v-progress-circular
                                    :rotate="360"
                                    :size="50"
                                    :width="4"
                                    :value="file.progress"
                                    color="success">
                                    {{ file.progress }}
                                  </v-progress-circular>
                                </v-flex>
                                <v-flex v-if="file.response && file.response.data">
                                  <div class="thumbnail">
                                    <v-img
                                      @click="previewImg(`/v1/media/${file.response.data.file}`)"
                                      width="65"
                                      aspect-ratio="1"
                                      :src="`/v1/media/${file.response.data.file}`"
                                      :alt="file.response.data.filename" />
                                  </div>
                                </v-flex>
                              </v-layout>
                            </li>
                          </ul>
                        </template>
                        <v-btn depressed class="text-none">
                          {{ $t('btn') }}
                        </v-btn>
                      </Upload>
                      <div v-if="verifyInfo.identity_file_name && identify.length === 0">
                        <div>
                          <v-img
                            @click="previewImg(`/v1/media/${verifyInfo.identity_file_url}`)"
                            width="65"
                            aspect-ratio="1"
                            :src="`/v1/media/${verifyInfo.identity_file_url}`"
                            :alt="verifyInfo.identity_file_name" />
                        </div>
                      </div>
                      <div class="thumbnail-close">
                        <v-icon @click="clearFile('identity')" color="#999">close</v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="12" sm="12" class="pb-0" v-if="verifyInfo.credit_card_enabled">
                <v-row no-gutters>
                  <v-col md="12" sm="12">
                    <div class="form_title">
                      <RequiredDot>
                        {{ $t('form_labels[1]') }}
                      </RequiredDot>
                      <div>
                        <v-tooltip top color="primary" max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on">info</v-icon>
                          </template>
                          <span>{{ $t('form_desc[1]') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="12" sm="12">
                    <div class="outlined">
                      <Upload
                        v-model="credit"
                        accept="image/*"
                        ref="creditUpload"
                        :postAction="'/v1/upload_client_verification_file/'"
                        :multiple="false">
                        <template v-slot:files="{ files }">
                          <ul>
                            <li v-for="file in files" :key="file.name">
                              <v-layout>
                                <v-flex shrink d-flex v-if="Math.floor(file.progress) !== 100">
                                  <v-progress-circular
                                    :rotate="360"
                                    :size="50"
                                    :width="4"
                                    :value="file.progress"
                                    color="success">
                                    {{ file.progress }}
                                  </v-progress-circular>
                                </v-flex>
                                <v-flex v-if="file.response && file.response.data">
                                  <div class="thumbnail">
                                    <v-img
                                      @click="previewImg(`/v1/media/${file.response.data.file}`)"
                                      width="65"
                                      aspect-ratio="1"
                                      :src="`/v1/media/${file.response.data.file}`"
                                      :alt="file.response.data.filename" />
                                  </div>
                                </v-flex>
                              </v-layout>
                            </li>
                          </ul>
                        </template>

                        <v-btn depressed class="text-none">
                          {{ $t('btn') }}
                        </v-btn>
                      </Upload>

                      <div v-if="verifyInfo.credit_card_file_name && credit.length === 0">
                        <div class="default">
                          <v-img
                            @click="previewImg(`/v1/media/${verifyInfo.credit_card_file_url}`)"
                            width="65"
                            aspect-ratio="1"
                            :src="`/v1/media/${verifyInfo.credit_card_file_url}`"
                            :alt="verifyInfo.credit_card_file_name" />
                        </div>
                      </div>
                      <div class="thumbnail-close">
                        <v-icon @click="clearFile('creditcard')" color="#999">close</v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="12" sm="12" class="pb-0" v-if="verifyInfo.social_account_enabled">
                <v-row no-gutters>
                  <v-col md="12" sm="12">
                    <div class="form_title">
                      <RequiredDot>
                        {{ $t('form_labels[2]') }}
                      </RequiredDot>
                      <div>
                        <v-tooltip top color="primary" max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on">info</v-icon>
                          </template>
                          <span>{{ $t('form_desc[2]') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="12" sm="12">
                    <v-row no-gutters>
                      <v-col md="12" sm="12">
                        <v-text-field
                          v-model="formData.social_account"
                          outlined
                          dense
                          :rules="[rules.required]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="12" sm="12">
                <div class="font-weight-bold">{{ $t('notes_title') }}</div>
                <ul style="list-style: disc">
                  <li>{{ $t('notes[0]') }}</li>
                  <li>{{ $t('notes[1]') }}</li>
                </ul>
              </v-col>

              <v-col>
                <v-btn
                  color="primary"
                  class="text-none text-right"
                  :loading="loading"
                  @click="submitVerificationInfo">
                  {{ $t('button.submit') }}
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card style="position: relative">
        <v-img :src="previewImgUrl" contain hight="100%" aspect-ratio="1" v-lazy></v-img>
        <div
          style="position: absolute; top: 20px; right: 20px; cursor: pointer"
          @click="showDialog = false">
          <v-icon color="primary">close</v-icon>
        </div>
      </v-card>
    </v-dialog>
  </PageContent>
</template>
<script>
import PageContent from '@/components/PageContent'
import RequiredDot from '@/components/RequiredDot.vue'
import Upload from '@/components/upload/Upload'
import { mapState } from 'vuex'
// import FileUpload from 'vue-upload-component'

import rules from '@/module/rules/index.js'
export default {
  name: 'Verify',
  i18n: {
    sharedMessages: {
      en: {
        btn: 'Upload Photo',
        verify_desc: [
          'Your account is currently frozen due to failed identity verification by our automated system. To proceed with the verification, we kindly ask you to submit the following documents at your earliest convenience to help us reactivate your account smoothly. '
        ],
        use: 'INTENDED USE OF SERVER(S)',
        form_labels: ['Identification', 'Credit Card', 'Social Media Accounts'],
        verify_success: 'Submit Successfully',
        success: '',
        form_desc: [
          "Please upload a clear photo of a government-issued ID (e.g., ID card, driver's license, passport) .",
          'Please upload a clear photo of your credit card(showing only the first three and last four digits).',
          'Please provide Links to your social media profiles (e.g., Facebook, LinkedIn, etc.).'
        ],
        placeholder: ['Social media account links'],
        social_medias: ['Web Site', 'Google+', 'Twitter', 'Gacebook', 'Linkedin'],
        identify: ['ID Card', 'Driver License', 'Passport'],
        notes_title: 'Notes:',
        notes: [
          'Please be assured that this information is solely for account verification purposes and will be strictly kept confidential. ',
          'Kindly wait for manual verification after submitting your information. If verification is approved, your account will be reactivated and the information will be cleared. If verification fails, you may resubmit or modify your details. We will notify you via email. Thank you! '
        ]
      },
      zh_CN: {
        btn: '上传文件',
        verify_desc: [
          '您的账户目前因自动化系统未能成功验证您的身份而被冻结。为了解决此问题，请您尽快提交以下文件，以便我们顺利为您重新激活账户。'
        ],
        use: '服务器用途',
        form_labels: ['身份信息', '信用卡', '社交媒体信息'],
        verify_success: '提交成功',
        form_desc: [
          '请上传一张清晰的政府签发身份证明（如身份证、驾驶证、护照）的照片。',
          '请上传一张清晰的信用卡照片（仅显示前三位和后四位数字）。',
          '请提供您的社交媒体资料链接（如 Facebook、LinkedIn 等）。'
        ],
        placeholder: ['社交账号链接'],
        social_medias: ['网址', '谷歌', '推特', '脸书', '领英'],
        identify: ['身份证', '驾驶执照', '护照'],
        notes_title: '注意事项：',
        notes: [
          '这些信息仅用于账户验证，且受到严格的保密管理。',
          '提交信息后请等待人工审核，审核通过后您的账号将重新激活，信息将被清除。如果审核失败，您可以重新提交或修改信息。我们将通过电子邮件通知您。感谢您的支持！'
        ]
      }
    }
  },
  components: { PageContent, Upload, RequiredDot },
  data() {
    return {
      rules,
      verifyInfo: {},
      socialType: '',
      loading: false,
      socialMedias: [],
      identify: [],
      credit: [],
      identifyLoading: false,
      creditCardLoading: false,
      formData: {},
      fileList: [],
      showDialog: false,
      previewImgUrl: ''
    }
  },
  computed: {
    ...mapState('client', ['clientInfo'])
  },
  watch: {
    credit(value) {
      if (value.length) {
        this.verifyInfo.credit_card_file_name = ''
      }
    },
    identify(value) {
      if (value.length) {
        this.verifyInfo.identity_file_name = ''
      }
    }
  },
  methods: {
    previewImg(imgUrl) {
      this.previewImgUrl = imgUrl
      this.showDialog = true
    },

    clearFile(type) {
      if (type === 'creditcard') {
        this.credit = []
        this.verifyInfo.credit_card_file_name = ''
        this.verifyInfo.credit_card_file_url = ''
      } else {
        this.identify = []
        this.verifyInfo.identity_file_name = ''
        this.verifyInfo.identity_file_url = ''
      }
    },
    getFileData(refName) {
      const ref = this.$refs[refName]
      return ref && ref.files.length && ref.files[0].success ? ref.files[0].response.data : {}
    },

    submitVerificationInfo() {
      const creditCardFile = this.getFileData('creditUpload')
      const identifyFile = this.getFileData('uploadIdentify')
      const enabledKey = ['credit_card_enabled', 'identity_enabled', 'social_account_enabled']
      const validKey = enabledKey.filter((item) => {
        return item in this.verifyInfo && this.verifyInfo[item] === true
      })
      const params = {}
      if (!this.$refs.form.validate()) {
        return
      }
      validKey.forEach((item) => {
        switch (item) {
          case 'credit_card_enabled':
            params.credit_card_file_name = creditCardFile
              ? creditCardFile.filename
                ? creditCardFile.filename
                : this.verifyInfo.credit_card_file_name
                ? this.verifyInfo.credit_card_file_name
                : ''
              : ''
            params.credit_card_file_url = creditCardFile
              ? creditCardFile.file
                ? creditCardFile.file
                : this.verifyInfo.credit_card_file_url
                ? this.verifyInfo.credit_card_file_url
                : ''
              : ''
            break

          case 'identity_enabled':
            params.identity_file_name = identifyFile
              ? identifyFile.filename
                ? identifyFile.filename
                : this.verifyInfo.identity_file_name
                ? this.verifyInfo.identity_file_name
                : ''
              : ''

            params.identity_file_url = identifyFile
              ? identifyFile.file
                ? identifyFile.file
                : this.verifyInfo.identity_file_url
                ? this.verifyInfo.identity_file_url
                : ''
              : ''
            console.log(this.verifyInfo, 'iden')

            break

          case 'social_account_enabled':
            params.social_account = this.formData.social_account || ''
            break

          default:
            break
        }
      })
      this.loading = true
      this.$http
        .put(`clients/${this.clientInfo.id}/update_verification_info/`, params)
        .then(() => {
          this.loading = false
          this.$message.success(this.$t('verify_success'))
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.detail)
        })
    }
  },
  mounted() {
    this.verifyInfo = Object.assign(
      {},
      JSON.parse(
        JSON.stringify(this.clientInfo.verification_info ? this.clientInfo.verification_info : {})
      )
    )
    this.formData.social_account = this.verifyInfo.social_account
  }
}
</script>
<style lang="scss">
.verify {
  .v-label {
    max-width: unset;
  }
  .v-input {
    padding-top: 0;
    .v-input__prepend-outer {
      display: none !important;
    }
  }
  .form_title {
    display: flex;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
      font-size: 20px !important;
    }
  }
  .outlined {
    border: 1px solid #999;
    padding: 6px;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }

  ul {
    list-style: none;
    order: 2;
  }
  .v-progress-circular__info {
    font-size: 12px;
  }

  .upload {
    display: flex;
    align-items: center;
    .upload-list {
      order: 2;
      .small-title {
        display: none;
      }
    }
    .v-file-upload {
      order: 1;
    }
  }
  .thumbnail-close {
    cursor: pointer;
    margin-left: auto;
    button {
      color: #999;
    }
  }
}
</style>
