<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    no-click-animation>
    <v-card class="new-tt">
      <v-toolbar dark color="primary" height="48" fixed>
        <v-btn depressed icon dark @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('button.new_ticket') }}</v-toolbar-title>
      </v-toolbar>
      <v-container class="panel-wrapper" grid-list-xl>
        <div style="padding-top: 48px">
          <v-layout column>
            <v-flex>
              <v-form ref="form" class="new-ticket">
                <v-layout wrap>
                  <v-flex xs12 sm6 d-flex>
                    <v-text-field
                      v-model="formData.subject"
                      counter="255"
                      :rules="[
                        $options.rules.required,
                        (value) => !value || value.length <= 255 || 'Maximum 255 characters'
                      ]"
                      clearable>
                      <template v-slot:label>
                        <RequiredDot>{{ $t('subject') }}</RequiredDot>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 d-flex>
                    <v-select
                      v-model="formData.department"
                      item-text="name"
                      item-value="id"
                      :items="departments"
                      :rules="[$options.rules.required]">
                      <template v-slot:label>
                        <RequiredDot>{{ $t('department') }}</RequiredDot>
                      </template>
                    </v-select>
                  </v-flex>
                  <template v-if="isSalesDepartment">
                    <v-flex xs12 sm6 d-flex>
                      <v-select
                        v-model="formData.product_category"
                        :items="categories"
                        item-text="text"
                        item-value="value"
                        clearable>
                        <template #label>
                          <span>{{ $t('product_category') }}</span>
                        </template>
                      </v-select>
                    </v-flex>
                  </template>
                  <template v-else>
                    <v-flex xs12 sm6 d-flex>
                      <v-autocomplete
                        v-model="formData.package"
                        item-text="name"
                        item-value="id"
                        :items="filterServices"
                        :loading="serviceLoading"
                        :disabled="itemDisabled"
                        clearable>
                        <template #label>
                          <span>{{ $t('service') }}</span>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 d-flex v-if="!itemDisabled">
                      <v-text-field
                        v-model.trim="formData.ip_or_domain"
                        :rules="[$options.rules.ipOrDomain]"
                        clearable>
                        <template #label>
                          <span>{{ $t('ip_or_domain') }}</span>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </template>
                  <v-flex xs12 sm6 d-flex>
                    <v-text-field
                      v-model="formData.cc"
                      :rules="[$options.rules.ccEmail]"
                      clearable
                      placeholder="exampleA@g.com, exampleB@g.com...">
                      <template #label>
                        <span>{{ $t('attrs.cc') }}</span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 d-flex>
                    <span v-t="'tips'" style="color: rgba(0, 0, 0, 0.54)"></span>
                  </v-flex>
                </v-layout>
                <Editor ref="editor"></Editor>
              </v-form>
            </v-flex>
            <v-flex>
              <Upload :size="limitSize.size" ref="fileupload" v-model="fileList">
                <template v-slot:files="{ files }">
                  <FileList @remove="removeFile" :files="files"></FileList>
                </template>
                <v-btn depressed class="ml-0 white--text text-none" dark color="blue-grey ">
                  {{ $t('button.attach_files') }}
                </v-btn>
              </Upload>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="blue darken-1 "
                  class="text-none mr-2"
                  text
                  @click="close()">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  class="text-none mr-2"
                  :loading="loading"
                  @click="ok">
                  {{ $t('button.send') }}
                  <v-icon right dark>send</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from '@/components/Editor.vue'
import Upload from '@/components/upload/Upload.vue'
import loadingMixin from '@/module/mixins/loading.mixin.js'
import FileList from '@/components/upload/FileList.vue'
import rules from '@/module/rules/index.js'
import RequiredDot from '@/components/RequiredDot.vue'
import { limitSize, departmentsI18n, email_support } from '@/module/constant.js'
import { mapActions } from 'vuex'

let dkeys = [
  'attachments',
  'department',
  'cc',
  'subject',
  'content',
  'package',
  'ip_or_domain',
  'product_category'
]
let createData = function (item) {
  let obj = {}
  dkeys.forEach((key) => {
    obj[key] = item && item[key] ? item[key] : ''
    if (key === 'attachments' && !obj[key]) {
      obj[key] = []
    }
    if (key === 'package' && !obj[key]) {
      obj[key] = null
    }
  })
  if (item && item.callback) {
    obj.callback = item.callback
  }
  return obj
}
export default {
  components: {
    Editor,
    Upload,
    FileList,
    RequiredDot
  },
  rules,
  i18n: {
    sharedMessages: {
      en: {
        subject: 'Subject',
        department: 'Department',
        service: 'Service',
        ip_or_domain: 'IP/Domain(optional)',
        product_category: 'Product Category',
        categories: ['VPS', 'GPU Server', 'Dedicated Server', 'Other'],
        tips: 'Thank you for contacting us. Providing adequate information or context will help us investigate your issue faster.',
        create_ticket: [
          'Your ticket ID {0} has been submitted successfully. Our support staff will get back to you within 1-2 hours. Thanks for your patience.',
          'Oops, you failed to create a new ticket. You can reach us at {0} for this issue.'
        ]
      },
      zh_CN: {
        subject: '问题简述',
        department: '问题类型',
        service: '产品',
        ip_or_domain: 'IP/域名（可选）',
        product_category: '产品分类',
        categories: ['VPS', 'GPU服务器', '物理服务器', '其它'],
        tips: '请描述您的服务器信息和问题概述',
        create_ticket: [
          '工单号  {0} 被成功创建,我们的服务团队会在1-2小时内回复您,感谢您的支持',
          '抱歉, 创建工单出错, 您可以通过发送邮件到 {0} 来联系我们'
        ]
      }
    }
  },
  computed: {
    attachments() {
      return this.fileList.map((o) => o.response.data)
    },
    salesDepartmentID() {
      if (this.departments.length > 0) {
        const obj = this.departments.find(
          (o) => o.name === this.$t(departmentsI18n['Sales Department'])
        )
        return obj ? obj.id : 0
      }
      return 0
    },
    isSalesDepartment() {
      return this.formData.department === this.salesDepartmentID
    },
    filterServices() {
      if (this.showAllServices) return this.services
      return this.services.filter((o) => ['Active', 'Suspended'].includes(o.status))
    }
  },
  data() {
    return {
      limitSize: limitSize,
      fileList: [],
      formData: createData(),
      departments: [],
      services: [],
      showAllServices: false,
      serviceLoading: false,
      itemDisabled: false,
      categories: []
    }
  },
  mixins: [loadingMixin],
  name: 'NewTicket',
  watch: {
    dialog(val) {
      if (!val) {
        this.formData = createData()
        this.fileList = []
        this.$refs.form.resetValidation()
        this.$refs.editor.setData('')
      }
    }
  },
  methods: {
    ...mapActions('application', ['getAllPackages']),
    open(val) {
      this.dialog = true
      this.itemDisabled = false
      if (val) {
        this.formData = createData(val)
        this.showAllServices = !!this.services.find((o) => o.id === val.package_id)
        this.$nextTick(() => {
          this.$refs.editor.setData(val.content)
          if (val.package_id || val.package) {
            this.formData.package = val.package_id || val.package
            this.itemDisabled = true
          }
        })
      }
    },
    $validator() {
      let flag = this.$refs.form.validate() && this.$refs.fileupload.getUploaded()
      if (flag) {
        this.createTicketData()
      }
      return flag
    },
    ok() {
      if (!this.$validator()) {
        return
      }
      let done = () => {
        this.loading = false
        this.dialog = false
      }
      this.loading = true
      this.sendTicket(this.formData)
        .then((res) => {
          this.$message.success(this.$t('create_ticket[0]', [res.number]))
          this.$listeners.confirm && this.$listeners.confirm(res)
          this.formData.callback && this.formData.callback()
          done()
        })
        .catch((e) => {
          done()
          this.$message.error(this.$t('create_ticket[1]', [email_support]))
          return Promise.reject(e)
        })
    },
    createTicketData() {
      this.formData = Object.assign(this.formData, {
        content: this.$refs.editor.getData(),
        attachments: this.attachments
      })
    },
    getDepartments() {
      this.$http
        .get(`/departments/`)
        .then((res) => {
          this.departments = res.results
            .filter((o) => o.name !== 'Operation Department')
            .map((o) => {
              o.name = this.$t(departmentsI18n[o.name])
              return o
            })
        })
        .catch((e) => {
          this.$message.error(e.detail)
        })
    },
    removeFile(index) {
      this.fileList.splice(index, 1)
    },
    ...mapActions('ticket', ['sendTicket']),
    // get services
    getServices() {
      this.serviceLoading = true
      this.getAllPackages()
        .then((res) => {
          const packages = res.map((item) => {
            return {
              id: item.id,
              name: `${item.name}(${item.status})`,
              status: item.status
            }
          })
          // sort by category and status
          packages.sort((a, b) => {
            if (a.name === b.name) {
              return a.status.localeCompare(b.status)
            }
            return a.name.localeCompare(b.name)
          })
          this.services = packages
        })
        .finally(() => {
          this.serviceLoading = false
        })
    }
  },
  created() {
    this.getDepartments()
    this.getServices()
    this.categories = [
      {
        text: this.$t('categories[0]'),
        value: 'VPS'
      },
      {
        text: this.$t('categories[1]'),
        value: 'GPU Server'
      },
      {
        text: this.$t('categories[2]'),
        value: 'Dedicated Server'
      }
    ]
    if (process.env.VUE_APP_ACTOR === 'ccs') {
      this.categories.push({
        text: 'APP',
        value: 'APP'
      })
    }
    this.categories.push({
      text: this.$t('categories[3]'),
      value: 'Other'
    })
  }
}
</script>

<style lang="scss">
.new-tt {
  .file-uploads-html5 {
    text-align: left;
  }
}

.new-ticket {
  .v-text-field {
    padding-top: 0;
  }
  fieldset {
    border: 1px solid !important;
  }

  .number {
    .v-text-field__slot {
      label.v-label,
      .v-label--active {
        transform: translateY(-2.25rem) translateX(-11rem);
        color: #000;
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }

  .v-input__slot {
    border: 1px solid #d9d9d9;
    padding: 0.5rem 1.25rem;

    &::before {
      border-color: inherit !important;
      content: '';
    }

    &::after {
      content: '';
      transform: scaleX(0) !important;
    }

    .v-label {
      transform: translateY(-2.25rem) translateX(-1.125rem);
      position: relative;

      span {
        position: relative;
        z-index: 1;
        width: 6.25rem;
        height: 1.875rem;
        color: #000;
        font-weight: 700;
        font-size: 1rem;
      }
    }

    .v-label--active {
      transform: translateY(-2.25rem) translateX(-1.125rem);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 0;
      }
    }
  }

  .v-input__slot:has(.error--text) {
    border: 1px solid #ff5252;
  }

  .v-input__slot:has(.primary--text) {
    border: 1px solid var(--v-primary-base);
  }

  .v-text-field__slot {
    label.v-label.v-label--active {
      transform: translateY(-2.25rem) translateX(-1.125rem);
      color: #000;
      font-weight: 700;
      font-size: 1rem;
    }

    .v-label--active.primary--text {
      color: #000 !important;
    }

    .v-label--active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1 !important;
      }
    }
  }
}
</style>
