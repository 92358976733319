import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { getPermission } from './permission'

Vue.use(Router)

const routers = [
  {
    path: '/',
    name: 'Home',
    component: (resolve) => require(['./views/home/HomeOverview.vue'], resolve),
    beforeEnter: (to, from, next) => {
      if (to.query.init) {
        store.state.client.isNewClient = true
      }
      next()
    }
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    meta: {
      title: 'menu.affiliate[1]',
      permission: ['menu.affiliate']
    },
    component: (resolve) => require(['./views/affiliate/index.vue'], resolve)
  },
  {
    path: '/document',
    name: 'Document',
    meta: {
      title: 'menu.doc[1]'
    },
    component: (resolve) => require(['./views/document/Document.vue'], resolve)
  },
  {
    path: '/ordering',
    name: 'Ordering',
    meta: {
      title: 'menu.all[1]'
    },
    redirect: () => {
      return getPermission(['menu.products.app']) ? '/ordering/app' : '/ordering/vps'
    },
    component: (resolve) => require(['./views/ordering/index.vue'], resolve),
    children: [
      {
        path: '/ordering/app',
        name: 'OrderingApp',
        meta: {
          title: 'menu.products.app[0]',
          i18n_text: 'menu.products.app[0]',
          permission: ['menu.products.app']
        },
        component: (resolve) => require(['./views/ordering/AppSearch.vue'], resolve)
      },
      {
        path: '/ordering/vps',
        name: 'OrderingVps',
        meta: {
          title: 'menu.products.vps[0]',
          i18n_text: 'menu.products.vps[0]',
          permission: ['menu.products.vps']
        },
        component: (resolve) => require(['./views/ordering/VpsSearch.vue'], resolve)
      },
      {
        path: '/ordering/gpu',
        name: 'OrderingGpu',
        meta: {
          title: 'menu.products.gpu[0]',
          i18n_text: 'menu.products.gpu[0]',
          permission: ['menu.products.gpu']
        },
        component: (resolve) => require(['./views/ordering/GpuSearch.vue'], resolve)
      },
      {
        path: '/ordering/dedicated',
        name: 'OrderingBareMetal',
        meta: {
          title: 'menu.dedicated[1]',
          i18n_text: 'menu.products.dedicated[0]',
          permission: ['menu.products.dedicated']
        },
        component: (resolve) => require(['./views/ordering/DedicatedSearch.vue'], resolve)
      },

      {
        path: '/ordering/domain',
        name: 'OrderingDomain',
        meta: {
          title: 'menu.domain[1]',
          i18n_text: 'menu.products.domain[0]',
          permission: ['menu.products.domain']
        },
        component: (resolve) => require(['./views/ordering/DomainSearch.vue'], resolve)
      },
      {
        path: '/ordering/ssl',
        name: 'OrderingSSL',
        meta: {
          title: 'menu.ssl[1]',
          i18n_text: 'menu.products.ssl[0]',
          permission: ['menu.products.ssl']
        },
        component: (resolve) => require(['./views/ordering/SslSearch.vue'], resolve)
      }
    ]
  },
  {
    path: '/myvps',
    name: 'VM',
    meta: {
      title: 'menu.vps[1]',
      i18n_text: 'menu.vps[0]',
      permission: ['menu.vps']
    },
    component: (resolve) => require(['./views/myvps/index.vue'], resolve)
  },
  {
    path: '/myvps/:appId',
    name: 'VPS detail',
    meta: {
      permission: ['menu.vps']
    },
    redirect: '/myvps/:appId/overview',
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/myvps/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },

      {
        path: '/myvps/:appId/server-manage',
        name: 'ServerManagement',
        component: (resolve) => require(['./views/applications/servermanage/index.vue'], resolve)
      },
      {
        path: '/myvps/:appId/guidance',
        name: 'Guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/myvps/:appId/events',
        name: 'Event',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      }
    ]
  },
  {
    path: '/emails',
    name: 'Email Announcement',
    meta: {
      title: 'menu.emails[0]'
    },
    component: (resolve) => require(['./views/emails/index.vue'], resolve)
  },
  {
    path: '/dns',
    name: 'dns',
    meta: {
      title: 'menu.dns[1]'
    },
    component: (resolve) => require(['./views/dns/index.vue'], resolve)
  },
  {
    path: '/ticket',
    name: 'ticket',
    meta: {
      title: 'menu.tickets[1]'
    },
    component: (resolve) => require(['./views/tickets/TicketList.vue'], resolve)
  },
  {
    path: '/ticket/:tid',
    name: 'ticket-details',
    component: (resolve) => require(['./views/tickets/TicketDetails.vue'], resolve)
  },
  {
    path: '/applications',
    name: 'APP',
    meta: {
      title: 'menu.apps[1]',
      i18n_text: 'menu.apps[0]',
      permission: ['menu.apps']
    },
    component: (resolve) => require(['./views/applications/Applications.vue'], resolve)
  },
  {
    path: '/domain',
    name: 'Domain',
    meta: {
      title: 'menu.domain[0]',
      i18n_text: 'menu.domain[0]',
      permission: ['menu.domain']
    },
    component: (resolve) => require(['./views/domain/index.vue'], resolve)
  },
  {
    path: '/domain/:appId',
    name: 'Domain',
    meta: {
      permission: ['menu.domain']
    },
    redirect: '/domain/:appId/overview',
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/domain/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },
      {
        path: '/domain/:appId/dns',
        name: 'DNSManagement',
        component: (resolve) => require(['./views/applications/DNSManagement/index.vue'], resolve)
      },
      {
        path: '/domain/:appId/guidance',
        name: 'Guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/domain/:appId/events',
        name: 'Event',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      }
    ]
  },
  {
    path: '/ssl',
    name: 'SSL',
    meta: {
      title: 'menu.ssl[0]',
      i18n_text: 'menu.ssl[0]',
      permission: ['menu.ssl']
    },
    component: (resolve) => require(['./views/sslServer/SSL.vue'], resolve)
  },
  {
    path: '/ssl/:appId',
    name: 'SSL Servers',
    meta: {
      permission: ['menu.ssl']
    },
    redirect: '/ssl/:appId/overview',
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/ssl/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },
      {
        path: '/ssl/:appId/guidance',
        name: 'Guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/ssl/:appId/events',
        name: 'Event',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      }
    ]
  },
  {
    path: '/applications/:appId',
    name: 'AppDetail',
    redirect: '/applications/:appId/overview',
    meta: {
      permission: ['menu.apps']
    },
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/applications/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/file-manager',
        name: 'FileManager',
        component: (resolve) => require(['./views/applications/filemanager/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/web-shell',
        name: 'WebShell',
        component: (resolve) => require(['./views/applications/shell/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/ftp-server',
        name: 'ftp',
        component: (resolve) => require(['./views/applications/ftp/FtpServer.vue'], resolve)
      },
      {
        path: '/applications/:appId/sql-config',
        name: 'SqlConfig',
        component: (resolve) => require(['./views/applications/sqlCfg/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/php-admin',
        name: 'phpAdmin',
        component: (resolve) => require(['./views/applications/phpAdmin/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/kibana',
        name: 'KBanner',
        component: (resolve) => require(['./views/applications/kibana/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/guidance',
        name: 'guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/domainSSL',
        name: 'DomainSSL',
        component: (resolve) => require(['./views/applications/domainSSL/DomainSSL.vue'], resolve)
      },
      {
        path: '/applications/:appId/dbuser',
        name: 'Databases & Users',
        component: (resolve) => require(['./views/applications/dbUser/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/activities',
        name: 'Activities',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      },
      {
        path: '/applications/:appId/events',
        name: 'Events',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      },
      {
        path: '/applications/:appId/snapshots',
        name: 'Snapshots',
        component: (resolve) => require(['./views/applications/Snapshots.vue'], resolve)
      },
      {
        path: '/applications/:appId/metrics',
        name: 'Metrics',
        component: (resolve) => require(['./views/applications/metrics/index.vue'], resolve)
      },
      {
        path: '/applications/:appId/security',
        name: 'Security',
        component: (resolve) => require(['./views/applications/security/index.vue'], resolve)
      }
    ]
  },
  {
    path: '/gpu-servers',
    name: 'GPU',
    meta: {
      title: 'menu.gpu[0]',
      i18n_text: 'menu.gpu[0]',
      permission: ['menu.gpu']
    },
    component: (resolve) => require(['./views/gpu/GPUServers.vue'], resolve)
  },
  {
    path: '/only-billing/:appId',
    name: 'OnlyBillingOverview',
    redirect: '/only-billing/:appId/overview',
    component: (resolve) => require(['./views/applications/OnlyBillingDetail.vue'], resolve),
    children: [
      {
        path: '/only-billing/:appId/overview',
        name: 'Overview',
        component: (resolve) =>
          require(['./views/applications/overview/OnlyBillingOverview.vue'], resolve)
      }
    ]
  },
  {
    path: '/gpu-servers/:appId',
    name: 'GPU Server',
    meta: {
      permission: ['menu.gpu']
    },
    redirect: '/gpu-servers/:appId/overview',
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/gpu-servers/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },

      {
        path: '/gpu-servers/:appId/server-manage',
        name: 'ServerManagement',
        component: (resolve) => require(['./views/applications/servermanage/index.vue'], resolve)
      },
      {
        path: '/gpu-servers/:appId/additional-software',
        name: 'AdditionalSoftware',
        component: (resolve) =>
          require(['./views/applications/additionalSoftware/index.vue'], resolve)
      },
      {
        path: '/gpu-servers/:appId/guidance',
        name: 'guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/gpu-servers/:appId/events',
        name: 'Events',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      }
    ]
  },
  {
    path: '/dedicated-servers',
    name: 'Bare Metal',
    meta: {
      title: 'menu.dedicated[1]',
      i18n_text: 'menu.dedicated[0]',
      permission: ['menu.dedicated']
    },
    component: (resolve) => require(['./views/bareMetal/DedicatedServers.vue'], resolve)
  },
  {
    path: '/dedicated-servers/:appId',
    name: 'Bare Metal Server',
    meta: {
      permission: ['menu.dedicated']
    },
    redirect: '/dedicated-servers/:appId/overview',
    component: (resolve) => require(['./views/applications/ApplicationDetail.vue'], resolve),
    children: [
      {
        path: '/dedicated-servers/:appId/overview',
        name: 'Overview',
        component: (resolve) => require(['./views/applications/overview/index.vue'], resolve)
      },

      {
        path: '/dedicated-servers/:appId/server-manage',
        name: 'ServerManagement',
        component: (resolve) => require(['./views/applications/servermanage/index.vue'], resolve)
      },
      {
        path: '/dedicated-servers/:appId/additional-software',
        name: 'AdditionalSoftware',
        component: (resolve) =>
          require(['./views/applications/additionalSoftware/index.vue'], resolve)
      },
      {
        path: '/dedicated-servers/:appId/guidance',
        name: 'guidance',
        component: (resolve) => require(['./views/applications/guidance/index.vue'], resolve)
      },
      {
        path: '/dedicated-servers/:appId/events',
        name: 'Events',
        component: (resolve) => require(['./views/applications/Activities.vue'], resolve)
      }
    ]
  },
  {
    path: '/billing',
    name: 'Billing',
    meta: {
      title: 'menu.billing[1]'
    },
    redirect: () => {
      return getPermission(['account.information.type.simple'])
        ? '/billing/invoices'
        : '/billing/billing-info'
    },
    component: (resolve) => require(['./views/billing/BillingDetail.vue'], resolve),
    children: [
      {
        path: '/billing/paymentmethod',
        name: 'Payment Method',
        meta: {
          permission: ['menu.billing.payment']
        },
        redirect: '/billing/paymentmethod/credit-card',
        component: (resolve) => require(['./views/billing/payment/PaymentMethod.vue'], resolve),
        children: [
          {
            path: '/billing/paymentmethod/credit-card',
            name: 'Credit Cards',
            component: (resolve) => require(['./views/billing/payment/Credit.vue'], resolve)
          },
          {
            path: '/billing/paymentmethod/paypal',
            name: 'Paypal',
            component: (resolve) => require(['./views/billing/payment/Paypal.vue'], resolve)
          }
        ]
      },
      {
        path: '/billing/invoices',
        name: 'Invoices',
        component: (resolve) => require(['./views/billing/invoices/Invoices.vue'], resolve)
      },
      // {
      //   path: '/billing/billing-info',
      //   name: 'BillingInfo',
      //   meta: {
      //     permission: ['menu.billing.information']
      //   },
      //   component: (resolve) => require(['./views/billing/billingInfo/index.vue'], resolve)
      // },
      {
        path: '/billing/credits',
        name: 'Credits',
        component: (resolve) => require(['./views/billing/credits/Credits.vue'], resolve)
      }
    ]
  },
  {
    path: '/account',
    name: 'Account Information',
    meta: {
      title: 'menu.account[0]'
    },
    component: (resolve) => require(['./views/accountInfo/index.vue'], resolve),
    children: [
      {
        path: '/account/information',
        name: 'menu.account[1]',
        component: (resolve) =>
          require(['./views/accountInfo/information/Information.vue'], resolve)
      },
      // {
      //   path: '/account/verify-info',
      //   name: 'menu.password[0]',
      //   component: (resolve) => require(['./views/accountInfo/verifyInfo/Verify.vue'], resolve)
      // },
      {
        path: '/account/password',
        name: 'menu.account_vertifiy[0]',
        component: (resolve) =>
          require(['./views/accountInfo/changePassword/ChangePassword.vue'], resolve)
      }
    ]
  },
  // {
  //   path: '/profile/account',
  //   name: 'Account Information',
  //   component: (resolve) => require(['./views/profile/Profile.vue'], resolve)
  // },
  // {
  //   path: '/profile/password',
  //   name: 'Change Password',
  //   component: (resolve) => require(['./views/profile/ChangePassword.vue'], resolve)
  // },
  // {
  //     path: '/profile',
  //     name: 'Profile',
  //     meta:{
  //         title:"menu.account[0]",
  //     },
  //     component: resolve => require(['./views/profile/index.vue'], resolve),
  //     children: [
  //
  //     ]
  // },
  {
    path: '/membership',
    name: 'Membership',
    meta: {
      permission: ['account.membership']
    },
    component: (resolve) => require(['./views/membership/index.vue'], resolve)
  },
  {
    path: '/check/:product/:plan_uuid',
    name: 'Check',
    component: (resolve) => require(['./views/check/index.vue'], resolve)
  },
  {
    path: '/invoice-detail/:invoiceId',
    name: 'InvoiceDetail',
    component: (resolve) => require(['./views/invoice/index.vue'], resolve)
  },
  {
    path: '/order-confirm/:confirmId',
    name: 'OrderConfirm',
    component: (resolve) => require(['./views/orderConfirm/Index.vue'], resolve)
  }
  // {
  //     path: '/payment/:invoiceId',
  //     name: 'payment',
  //     component: resolve => require(['./views/payment/payment.vue'], resolve)
  // }
]

const getRouters = function (routes) {
  return routes.reduce((per, current) => {
    if (current.meta && current.meta.permission) {
      if (getPermission(current.meta.permission)) {
        per.push(current)
      }
    } else {
      per.push(current)
    }
    if (current.children) {
      current.children = getRouters(current.children)
    }
    return per
  }, [])
}

const router = new Router({
  mode: 'history',
  routes: getRouters(routers)
})

export default router
